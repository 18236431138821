export function show(...params) {
  if (!params.length) {
    handleShow.call(this);

    return;
  }

  if (typeof params[0] === 'function') handleShow.call(this, params[0], params[1]);
  else handleShow.call(this, 400, params[0]);
}

function handleShow(duration = 400) {
  const initialDisplay = `${this.css('display')}`;
  const initiallyNotVisible = initialDisplay === 'none';

  const displayBlockRule = initiallyNotVisible ? `block` : ``;
  const beginningOpacityRule = `0`;
  const endingOpacityRule = `1`;
  const transitionRule = `opacity ${duration}ms ease-in-out`;

  this.element.style.display = displayBlockRule;
  this.element.style.transition = transitionRule;
  this.element.style.opacity = beginningOpacityRule;

  setTimeout(() => {
    this.element.style.opacity = endingOpacityRule;

    setTimeout(() => {
      this.element.style.transition = '';
      this.element.style.opacity = '';
      this.element.style.display = '';
      const notVisible = `${this.css('display')}` === 'none';
      this.element.style.display = notVisible ? 'block' : '';
    }, duration);
  }, 1);
}

// id="test" style="display: none;"
