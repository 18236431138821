/*
 * Handles Audio and Captions of THEOplayer
 */
(function (window, d3) {
  'use strict';

  // define config
  let config = d3 && d3.config;

  // check dependencies
  if (!config) {
    throw new Error('D3vp:: videoAudioCaptions.config missing');
  }

  // namespace
  d3.ocsvp = d3.ocsvp || {};
  d3.ocsvp.audiocaptions = d3.ocsvp.audiocaptions || {};
  let namespace = d3.ocsvp.audiocaptions,
    epgType = config.video.types.epg || 'EPG',
    linearType = config.video.types.linear || 'linear',
    liveType = config.video.types.live || 'live',
    playerCaptionsStorageKey = 'videoPlayer.captionLabel',
    captionsEnabled = 'showing',
    captionsTurnedOff = 'off',
    captionCodeOffValue = -1,
    captionCode = '',
    captionLabelOffValue = '',
    captionLabel = captionLabelOffValue,
    subTracks = null,
    browserLanguage = d3.utils.language.getCaptionsBrowserLanguage();

  //Audio Tracks
  namespace.manageAudioTracks = function (audioTracks) {
    //TODO: because of TheOPlayer's multiaudio bug, waiting to be fixed
  };
  //End Audio Tracks

  //Captions
  namespace.manageCaptions = function (player) {
    //console.log("d3vp:: manageCaptions");
    //console.log(player);

    if (!player || d3.utils.code.checkIsNil(player.currentContent)) {
      return;
    }

    subTracks = player.playerInstance.textTracks;
    let subTracksLength = subTracks.length;

    if (d3.utils.code.checkIsNil(subTracks) || subTracksLength === 0) {
      //Turn captions off
      //setCaptions(0);

      return;
    } else {
      getCaptions(subTracks);
    }

    if (checkLiveTypes(player.currentContent.additionalOptions.type)) {
      //setCaptions(obj.captionCode);

      return;
    }

    //Turns captions off if no audioTracks data are available
    //To be reviewed
    //if (d3.utils.code.checkIsNil(audioTracks)) {

    //    //Turn captions off
    //    //setCaptions(0);

    //    return;

    //}

    //var captionCode = obj.captionCode,
    //    captionLabel = obj.captionLabel;

    //console.log("manageCaptions() textTracks");
    //console.log(textTracks);

    //if (textTracksLength > 0) {

    //    //Multi-audio
    //    //TODO: because of TheOPlayer's multiaudio bug, waiting to be fixed
    //    //checkAndSetCaptions(audioLabel, captionLabel, captionCode);

    //    if (d3.utils.code.checkIsNil(subTracks)) {

    //        getCaptions(textTracks);

    //    }

    //    checkAndSetCaptions("", captionLabel, captionCode, player);

    //} else {

    //    //Single-audio = no audio tracks data in .m3u8 file = no default subtitles
    //    //Turn captions off
    //    //playerInstance.setCurrentCaptions(0); // off

    //}

    const playerCaptions = window?.localStorage.getItem(playerCaptionsStorageKey);

    if (playerCaptions) {
      captionCode = playerCaptions;
    }

    checkAndSetCaptions('', captionLabel, captionCode, player);
  };

  namespace.resetCaptionsCodeAndLabel = function (subTrack) {
    if (d3.utils.code.checkIsNil(subTrack)) {
      captionCode = captionLabelOffValue;
      captionLabel = captionLabelOffValue;
    }
  };

  namespace.setCaptionStorage = function (subTrack) {
    if (d3.utils.code.checkIsNil(subTrack) || subTrack.length === 0) {
      window.localStorage.removeItem(playerCaptionsStorageKey);

      return;
    }

    let escaped = subTrack.language.replace(/"/g, '');

    //set current CC object
    window.localStorage.setItem(playerCaptionsStorageKey, escaped);
  };

  //helpers
  function checkLiveTypes(type) {
    if (type === epgType || type === linearType || type === liveType) {
      return true;
    }

    return false;
  }

  function checkAndSetCaptions(audioLabel, captionLabel, captionCode, player) {
    let myPlayer = player || null;

    if (d3.utils.code.checkIsNil(myPlayer)) {
      return;
    }

    //TODO: because of TheOPlayer's multiaudio bug, waiting to be fixed
    // Show captions if browser language != from already set captions

    //if (audioLabel.toLowerCase() !== captionLabel.toLowerCase()) {
    //    setCaptions(captionCode);
    //}

    setCaptions(captionCode, myPlayer);
  }

  function setCaptions(captionCode, player) {
    let myPlayer = player || null;

    if (d3.utils.code.checkIsNil(myPlayer)) {
      return;
    }

    //var intCaptionCode = parseInt(captionCode, 10);
    let intCaptionCode = captionCode;

    if (intCaptionCode !== captionLabelOffValue) {
      //localStorage.removeItem(playerCaptionsStorageKey);

      //JwPlayer methods unset(set to 0) + set captions
      //playerInstance.setCurrentCaptions(0);
      //playerInstance.setCurrentCaptions(intCaptionCode);

      let captionsObject = d3.utils.code.findAndGetInObjArray(
        subTracks,
        'language',
        intCaptionCode
      );

      if (captionsObject) {
        myPlayer.playerInstance.textTracks[captionsObject.index].mode = captionsEnabled;
      }
    }
  }

  function getCaptions(subList) {
    if (d3.utils.code.checkIsNil(subList) || subList.length === 0) {
      return;
    }

    let captionCodeObj = getCaptionCode(browserLanguage, subList, true);

    if (captionCodeObj === captionCodeOffValue) {
      return;
    }

    subTracks = subList;
    captionCode = captionCodeObj.label.toLowerCase();
    captionLabel = captionCodeObj.label.toLowerCase();
  }

  function getCaptionCode(currentCultureCaption, subList, returnObj) {
    returnObj = d3.utils.code.checkAndSetDefaultValue(returnObj, false);

    let _captionCode = captionCodeOffValue,
      subsLabel = localStorage.getItem(playerCaptionsStorageKey);

    if (
      d3.utils.code.checkIsNil(subsLabel) ||
      subsLabel === captionLabelOffValue ||
      subsLabel.toLowerCase() === captionsTurnedOff
    ) {
      subsLabel = captionLabelOffValue;
      captionCode = captionLabelOffValue;

      return captionCodeOffValue;
    }

    let newCultureCaptionObj = getCurrentCultureAudioIndex(
        subList,
        'language',
        subsLabel,
        returnObj
      ),
      newCultureCaptionCode = newCultureCaptionObj;

    if (newCultureCaptionObj === captionCodeOffValue) {
      return captionCodeOffValue;
    }

    if (returnObj) {
      newCultureCaptionCode = newCultureCaptionObj.label;
    }

    if (newCultureCaptionCode !== captionCodeOffValue) {
      _captionCode = newCultureCaptionCode;
      captionCode = newCultureCaptionCode;
    }

    if (returnObj) {
      return {
        index: _captionCode,
        label: newCultureCaptionObj.label,
      };
    } else {
      return _captionCode;
    }
  }

  function getCurrentCultureAudioIndex(items, prop, compareValue, returnObj) {
    returnObj = d3.utils.code.checkAndSetDefaultValue(returnObj, false);

    let itemsLength = items.length,
      index = -1,
      i = 0;

    if (itemsLength > 0) {
      for (i; i < itemsLength; i = i + 1) {
        if (items[i][prop].toLowerCase() == compareValue.toLowerCase()) {
          if (returnObj) {
            return {
              index: i,
              label: items[i][prop].toLowerCase(),
            };
          } else {
            return i;
          }
        }
      }

      if (returnObj) {
        return {
          index: 0,
          label: items[0][prop].toLowerCase(),
        };
      }
    }

    return index;
  }
})(window, window.deltatre || {});
