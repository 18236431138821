export function on(events, ...params) {
  let handler = null;
  let selector = null;
  let data = null;

  if (params.length === 1 && typeof params[0] === 'function') handler = params[0];

  if (params.length === 2) {
    selector = params[0];
    handler = params[1];
  }

  if (params.length === 3) {
    selector = params[0];
    data = params[1];
    handler = params[2];
  }

  if (!handler) {
    console.warn('D3 DOM: Unsupported Parameters');

    return;
  }

  events.split(' ').forEach((event) => {
    this.elementList.forEach((element) => {
      element.addEventListener(event, (e) => {
        e.data = data;
        if (selector) {
          const selectedElements = [...element.querySelectorAll(selector)];
          const parent = selectedElements.find(
            (selectedElement) => selectedElement.contains(e.target) || selectedElement === e.target,
          );
          if (parent) {
            handler.call(parent, e);
          }
        } else handler.call(e.target || e.currentTarget, e);
      });
    });
  });
}
