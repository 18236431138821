import {RegwallTracking} from '../components/regwall-vod/regwall-tracking';
import _ from 'underscore';
import dom from '../../scripts/components/utils/dom/index';

/*
 * Initializes d3vp video components when page is ready
 */
(function (window, d3) {
  'use strict';

  let swiperCarouselInstance,
    player,
    isRtl = false,
    countPlay = 0;

  let userSignedIn = false;
  let initializePlayer = window.deltatre.isEmbedded;
  let gigyaLoaded = window.deltatre.isEmbedded;
  let regwallTracking = new RegwallTracking();
  let playerSelectors = '#video-player-container, #audio-player-container';
  let searchPanelOpened = false;

  // namespace
  d3.d3vp = d3.d3vp || {};
  let namespace = d3.d3vp;

  // define utils
  let utils = namespace.utils;

  // check dependencies
  if (!namespace.utils) {
    throw new Error('D3vp::utils missing');
  }
  if (!namespace.d3vpCore) {
    throw new Error('D3vp::d3vpCore missing');
  }
  if (!namespace.d3vpTheoplayerAdapter) {
    throw new Error('D3vp::d3vpTheoplayerAdapter missing');
  }
  if (!(d3.ocsvp && d3.ocsvp.tracking)) {
    throw new Error('OCSvp::tracking missing');
  }
  if (!(d3.ocsvp && d3.ocsvp.advertising)) {
    throw new Error('OCSvp::advertising missing');
  }
  if (!(d3.ocsvp && d3.ocsvp.audiocaptions)) {
    throw new Error('OCSvp::audiocaptions missing');
  }
  if (!(d3.ocsvp && d3.ocsvp.videomark)) {
    throw new Error('OCSvp::videomark missing');
  }
  if (!(d3.ocsvp && d3.ocsvp.countdown)) {
    throw new Error('OCSvp::countdown missing');
  }

  // define trace
  let trace = new utils.Trace('videoplayerPageready');

  // tracking
  let tracking = d3.ocsvp.tracking;

  // advertising
  let advertising = d3.ocsvp.advertising;

  // audio and captions
  let audiocaptions = d3.ocsvp.audiocaptions;

  // videomark
  let videomark = d3.ocsvp.videomark;

  // countdown
  let countdown = d3.ocsvp.countdown;

  dom(document).ready(function () {
    if (window.deltatre?.isGigyaLoadedTriggered) {
      checkIfUserLogged();
    } else {
      document.addEventListener('gigyaLoaded', checkIfUserLogged, {once: true});
    }

    // Event listener for timebomb regwall implementation
    document.addEventListener(window.deltatre.player.events.regwallInitPlayer, function () {
      namespace.initPlayer();
    });

    if (window.deltatre.isEmbedded) {
      namespace.initPlayer();
    }
  });

  function checkIfUserLogged() {
    if (gigya?.accounts) {
      gigya.accounts.getAccountInfo({
        include: 'data',
        callback: function callback(response) {
          gigyaLoaded = true;
          userSignedIn = response.errorCode == 0;
          initializePlayer = shouldPlayerBeInitialized(userSignedIn);
          namespace.initPlayer();
        },
      });
    }
  }

  function shouldPlayerBeInitialized(signedIn) {
    if (signedIn) {
      return true;
    } else {
      return !regwallTracking.isRegwallVodMatch();
    }
  }

  namespace.initPlayer = function (disableInitPrimetime = true) {
    // initialize player

    if (!gigyaLoaded) {
      return;
    }

    let success = function () {
      dom(playerSelectors).removeClass('d-none');
      dom('#prime-video-box-container').removeClass('d-none');
      initializeTheoplayer(initializePlayer);
    };

    let fail = function () {
      dom('#prime-video-player-container').removeClass('d-none');
    };

    if (primetimeAuthenticationRequired() && !disableInitPrimetime) {
      authenticatePrimetime(success, fail);
    } else {
      dom(playerSelectors).removeClass('d-none');
      initializeTheoplayer(initializePlayer);
    }

    if (!initializePlayer) {
      return;
    }

    let isRtlPos = d3.utils.code.getLanguageFromHtml().toUpperCase().indexOf('AR');

    if (isRtlPos > -1) {
      isRtl = true;
    }

    initOriginalsSwiperCarousel();
    multiSportPlaylistsControl();
    podcastPlaylistsControl();
  };

  let isPrimeTimeLogged = function () {
    let logged = false;
    if (
      document.cookie.indexOf('primeTimeAuthenticated=') !== -1 &&
      document.cookie.indexOf('primeTimeAuthenticated=;') === -1
    ) {
      logged = true;
    }

    return logged;
  };

  // helper functions
  const primetimeAuthenticationRequired = function () {
    return (
      document.getElementById('prime-video-player-container') ||
      document.getElementById('prime-playlist-item-container')
    );
  };

  const initOriginalsSwiperCarousel = () => {
    let $playlistSwiper = dom('.playlist__originals');

    if ($playlistSwiper.length) {
      swiperCarouselInstance = new d3.Swiper({
        container: document.getElementById('playlist-swiper'),
        controls: {
          left: document.querySelector('.controls-left'),
          right: document.querySelector('.controls-right'),
        },
        isRtl: isRtl,
      });
    }
  };

  const authenticatePrimetime = function (success, fail) {
    if (primeTimeAuthentication) {
      primeTimeAuthentication(success, fail);
    } else {
      fail();
    }
  };

  let initSyndicatedPlaylistSwiperCarousel = function () {
    let $playlistSwiper = dom('#playlist-swiper');

    if ($playlistSwiper.length) {
      swiperCarouselInstance = new d3.Swiper({
        container: document.getElementById('playlist-swiper'),
        controls: {
          left: document.querySelector('.controls-left'),
          right: document.querySelector('.controls-right'),
        },
        isRtl: isRtl,
      });
      $playlistSwiper.find('a').on('click', function (e) {
        e.preventDefault();
        if (!dom(e.target).closest('a').hasClass('cursor-default')) {
          let $link = dom(e.target);
          if (!$link.is('a')) {
            if (!$link.closest('a').hasClass('cursor-default')) {
              $link = $link.closest('a');
            }
          }
          let $ep = $link.find('[data-episode-index]');
          if ($ep.length > 0) {
            let index = $ep.first().attr('data-episode-index');
            if (index) {
              player.gotoTrack(index, function () {
                player.play();
              });
            }
          }
        }
      });
    }
  };

  let managePrimeTimeMultisportPlaylist = function (playlists, item) {
    if (
      (window.deltatre.isEmbedded && !window.deltatre.isEmbedded) ||
      (playlists.find('.playlist-items--item').find('.authenticated-content--label').length > 0 &&
        !isPrimeTimeLogged())
    ) {
      if (dom(item).find('.authenticated-content--label').length > 0) {
        if (dom('#prime-video-player-container').hasClass('d-none')) {
          dom('#prime-video-player-container').removeClass('d-none');
        }
        if (dom('#prime-playlist-item-container').hasClass('d-none')) {
          dom('#prime-playlist-item-container').removeClass('d-none');
        }

        if (!dom(playerSelectors).hasClass('d-none')) {
          dom(playerSelectors).addClass('d-none');
        }
        if (player && player.pause) {
          player.pause();
        }
      } else {
        if (!dom('#prime-video-player-container').hasClass('d-none')) {
          dom('#prime-video-player-container').addClass('d-none');
        }
        if (!dom('#prime-playlist-item-container').hasClass('d-none')) {
          dom('#prime-playlist-item-container').addClass('d-none');
        }

        if (dom(playerSelectors).hasClass('d-none')) {
          dom(playerSelectors).removeClass('d-none');
        }

        initializeTheoplayer();
      }
    }
  };

  const multiSportPlaylistsControl = function () {
    let playlists = dom('.js-podcast-playlist, .playlist-items--container');

    if (playlists.length > 0) {
      document.addEventListener(window.deltatre.player.events.switchVideo, function (e) {
        playlists
          .find('figcaption.current')
          .removeClass('current')
          .find('.article--label')
          .addClass('d-none');

        playlists
          .find(
            '.js-podcast-playlist-item[data-slug=' +
            e.slug +
            '], .playlist-items--item[data-slug=' +
            e.slug +
            ']',
          )
          .find('figcaption')
          .addClass('current');
      });

      playlists
        .find('.js-podcast-playlist-item, .playlist-items--item')
        .not('.cursor-default')
        .on('click', function (e) {
          if (!e.target.classList.contains('link-discover')) {
            e.preventDefault();
            managePrimeTimeMultisportPlaylist(playlists, e.currentTarget);

            let event = document.createEvent('Event');
            event.initEvent(window.deltatre.player.events.switchVideo, true, true);
            event.slug = dom(e.currentTarget).attr('data-slug');
            d3.ocsvp.playlist.activeVideo = event.slug;

            document.dispatchEvent(event);

            let this$ = dom(e.currentTarget);
            let url = this$.attr('data-href');
            window.deltatre.tracking.track('Link Clicked', {
              link_tag: this$.attr('data-title'),
              onward_journey_url: url,
              destination_url: url,
              external_url: url,
            });
          }
        });
    }
  };

  const podcastPlaylistsControl = function () {
    let playlists = dom('.js-podcast-playlist-container');
    if (playlists.length > 0) {
      document.addEventListener(window.deltatre.player.events.switchPodcast, function (e) {
        playlists.find('.card.current').removeClass('current');
        playlists
          .find('.js-podcast-playlist-item[data-slug=' + e.slug + '] .card')
          .addClass('current');

        dom('#podcast-detail-title').html(e.title);
        dom('#podcast-detail-description').html(e.shortDescription);
        dom('#description-view > .body-content').html(e.description);
        dom('#transcript-containter > .body-content').html(e.Transcript);

        dom.ajax({
          method: 'GET',
          url: e.relatedUrl,
          dataType: 'html',
          cache: true,
          success: (data) => {
            dom('#podcast-related').html(data);
          },
        });

        dom.ajax({
          method: 'GET',
          url: e.topicsUrl,
          dataType: 'html',
          cache: true,
          success: (data) => {
            data = dom.trim(data);
            if (data !== undefined && data !== '') {
              dom('#podcast-tags').replaceWith(data);
            } else {
              dom('#podcast-tags').html('');
            }
          },
        });

        window.history.pushState(e.description, e.title, e.url);

        if (e.index) {
          player.gotoTrack(e.index, function () {
            player.play();
          });
        }
      });

      playlists.find('.js-podcast-playlist-item').on('click', function (e) {

        if (!e.target.classList.contains('link-discover')) {

          e.preventDefault();
          let event = document.createEvent('Event');
          event.initEvent(window.deltatre.player.events.switchPodcast, true, true);
          event.url = dom(e.currentTarget).attr('data-href');
          event.slug = dom(e.currentTarget).attr('data-slug');
          event.title = dom(e.currentTarget).attr('data-title');
          event.shortDescription = dom(e.currentTarget).attr('data-short-description');
          event.description = dom(e.currentTarget).attr('data-description');
          event.Transcript = dom(e.currentTarget).attr('data-transcript');
          event.relatedUrl = dom(e.currentTarget).attr('data-related-url');
          event.topicsUrl = dom(e.currentTarget).attr('data-topics-url');
          event.index = dom(e.currentTarget).attr('data-episode-index');
          document.dispatchEvent(event);

          let this$ = dom(e.currentTarget);
          let url = this$.attr('data-href');
          window.deltatre.tracking.track('Link Clicked', {
            link_tag: this$.attr('data-title'),
            onward_journey_url: url,
            destination_url: url,
            external_url: url,
          });
        }
      });
    }
  };

  // main player initialization
  const initializeTheoplayer = function (initPlayer = false) {
    trace('initialize THEOplayer');

    // define components
    let d3vpCore = namespace.d3vpCore;
    let d3vpTheoplayerAdapter = namespace.d3vpTheoplayerAdapter;

    // d3vp core configuration
    let config = {
      selector: '[data-d3vp-plugin=THEOplayer]',
    };

    config.eventHandlers = getEventHandlers();

    // tokenization configuration
    let tokenizationUrl = d3.utils.dom.getMetaTagValue(d3.config.metaTags.tokenizationApiDomainUrl);

    config.tokenization = {
      tokenizationDomain: tokenizationUrl,
    };

    // initialize core and adapter
    if (initPlayer) {
      const playerOptionsString = document.querySelector(config.selector).dataset.options ?? {};
      const options = JSON.parse(playerOptionsString);

      d3vpTheoplayerAdapter.init({
        allowNativeFullscreen: true,
        isEmbeddable: window.deltatre.isEmbedded ? true : false,
        license: window.deltatre.player.license,
        libraryLocation: window.deltatre.player.libraryLocation,
        disableYoubora: options?.additionalOptions?.disableYoubora,
        ui: {
          allowNativeFullscreen: true,
        },
      });

      d3vpCore.init(config);

      document.addEventListener('keydown', getPressedKey); //Else player shortcuts are always enabled.
    }
  };

  function togglePlay() {
    if (document.getElementsByClassName('vjs-paused').length > 0) {
      raiseVideoPlayerEvent(window.deltatre.player.events.startPlayer);
    } else {
      raiseVideoPlayerEvent(window.deltatre.player.events.pausePlayer);
    }
  }

  function preventStandardHotKeyActions(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  function getPressedKey(event) {
    const pressedKey = event.key;
    const isInputTarget = event?.target?.tagName?.toLowerCase() === 'input' ?? false;

    // Ignore 'space' key when input from search is triggered
    if (pressedKey === ' ' && !isInputTarget) {
      togglePlay();
      preventStandardHotKeyActions(event); //Stops the default key behaviour like jumping the page with space.
    }
  }

  // helper props
  let videoProps = {
    audio_language: null,
    subtitle_language: null,
    bitrate: null,
    resolution: null,
    video_progress: null,
    watch_time: null,
  };

  //playlist helper
  let findVodInPlaylistBySlug = function (slug) {
    let player = this;
    let playlist = player.playlist;
    if (playlist && playlist.length > 0) {
      return playlist.find(function (v) {
        return v.slug === slug;
      });
    }

    return null;
  };

  let raiseVideoPlayerEvent = function (event) {
    if (event) {
      let createdEvent = new Event(event, {bubbles: true, cancelable: false});
      document.dispatchEvent(createdEvent);
    }
  };

  let raiseSwitchVideoEvent = function (slug) {
    if (slug && slug.length > 0) {
      let event = document.createEvent('Event');
      event.initEvent(window.deltatre.player.events.switchVideo, true, true);
      event.slug = slug;
      document.dispatchEvent(event);
    }
  };

  let raiseSwitchLiveChannelEvent = function (slug) {
    if (slug && slug.length > 0) {
      let event = document.createEvent('Event');
      event.initEvent(window.deltatre.player.events.switchLiveChannel, true, true);
      event.channelSlug = slug;
      document.dispatchEvent(event);
    }
  };

  //end playlist helper
  //Fired before onContentReady
  let onPlayerCreated = function () {
    this.playerInstance.ads.addEventListener('adbegin', tracking.handlerAdBegin);
    this.playerInstance.ads.addEventListener('adend', tracking.handlerAdEnd);
    this.playerInstance.ads.addEventListener('adimpression', tracking.handlerAdPlaying);
    this.playerInstance.ads.addEventListener('adskip', tracking.handlerAdSkip);

    this.playerInstance.videoTracks.addEventListener('addtrack', function (event) {
      event.track.addEventListener('activequalitychanged', function () {
        videoProps = tracking.updateVideoProps(player, videoProps);
      });
    });

    this.playerInstance.textTracks.addEventListener('addtrack', function () {
      audiocaptions.manageCaptions(player);
    });

    this.playerInstance.abr.strategy = 'quality';
  };

  let onContentReady = async function (content) {
    let slug = content.map((x) => x.slug)[0];

    const data = await fetchRegwallData(slug);

    // Always initialize embedded playlist
    if (!userSignedIn && data?.isRegwall && !window.deltatre.isEmbedded) {
      return;
    }

    let date = new Date();
    let ts = date.getTime();
    if (
      !!this.tokenizer &&
      !!this.tokenizer.config &&
      !!this.tokenizer.config.pattern &&
      this.tokenizer.config.pattern.indexOf('_ts=') < 0
    ) {
      if (this.tokenizer.config.pattern.indexOf('_ts=') < 0) {
        if (this.tokenizer.config.pattern.indexOf('?') > -1) {
          this.tokenizer.config.pattern = this.tokenizer.config.pattern + '&_ts=' + ts;
        } else {
          this.tokenizer.config.pattern = this.tokenizer.config.pattern + '?_ts=' + ts;
        }
      } else {
        let ptn = this.tokenizer.config.pattern.substr(
          0,
          this.tokenizer.config.pattern.indexOf('_ts') + 3,
        );
        this.tokenizer.config.pattern = ptn + '=' + ts;
      }
    }

    player = this;

    let getHostNameFromString = function (url) {
      let http = '',
        hostname;

      if (window.deltatre.utils.code.checkIsNil(url)) {
        return;
      }

      let urlIndexOf = url.indexOf('://');

      if (urlIndexOf > -1) {
        http = url.substring(0, urlIndexOf + 3);

        let myHostname = url.substring(urlIndexOf + 3);

        hostname = myHostname.split('/')[0];
      } else {
        hostname = url;
      }

      //find & remove port number
      hostname = hostname.split(':')[0];

      //find & remove "?"
      hostname = hostname.split('?')[0];

      return http + hostname;
    };

    let targetingConsent = false;

    if (d3.oneTrust) {
      let allConsents = d3.oneTrust.getCookieConsent();
      targetingConsent = d3.oneTrust.isTargetingCookieEnabled(allConsents);
    }

    let opt = this.options;

    let youboraAccount = (opt && opt.youboraAccount) || null;
    let enableAdvertising =
      targetingConsent &&
      typeof opt !== 'undefined' &&
      opt.enableAdvertising !== undefined &&
      opt.enableAdvertising !== null
        ? opt.enableAdvertising
        : true;

    let isMuted = (opt && opt.isMuted) || null;

    if (isMuted) {
      this.playerInstance.muted = true;
    }

    if (opt?.enableFullScreenLandscape && window.deltatre.utils.browser.isMobile.phone()) {
      window.addEventListener('orientationchange', (event) => {
        setTimeout(() => {
          window.deltatre.d3vp.d3vpCore.adaptedPlayers[0].playerInstance.presentationMode =
            event.target.screen.orientation.angle == 90 ? 'fullscreen' : 'inline';
        }, 200);
      });
    }

    for (let i = 0; i < content.length; i++) {
      if (!!content[i].tokenization === false && !!this.tokenizer && !!this.tokenizer.config) {
        content[i].tokenization = {
          // CUSTOMIZE TOKENIZATIOn
          domain: getHostNameFromString(content[i].src),
        };
      }

      if (enableAdvertising && advertising) {
        if (!regwallAdvDisabled()) {
          let vast = advertising.getVastTag(content[i]);
          if (vast) {
            content[i].advertising = [
              {
                sources: vast,
                integration: 'google-ima',
              },
            ];
          }
        } else {
          window.localStorage.removeItem(`is-regwall-signup`);
        }
      }
    }

    this.setContent(content);

    let currentContent = this.currentContent || null;
    tracking.setContent(currentContent);

    // Do not wait Youbora. It will start asynchronously.
    tracking.setYoubora(player, youboraAccount);

    tracking.setConviva(this, content);

    if (isGoogleDaiEnabled() && enableAdvertising && currentContent) {
      advertising.setGoogleDAI(this);
    }

    this.finalizeContent();

    // If missing video content, player.source.sources.src is null/undefined
    if (!player.source?.sources?.src) {
      hideTheoPlayerPreloadPoster();
    }

    countdown.setPlayButton();

    countdown.setPlayPauseButton();

    //Checks if is syndicated player
    if (window.deltatre.isEmbedded) {
      initSyndicatedPlaylistSwiperCarousel();
    }

    //listen to external switchVideo event
    document.addEventListener(window.deltatre.player.events.switchVideo, switchVideoHandler);

    //listen to external switchPodcast event
    document.addEventListener(window.deltatre.player.events.switchPodcast, function (e) {
      let slug = e.slug;
      if (slug && player.playlist && player.playlist.length > 0) {
        let vod = findVodInPlaylistBySlug.call(player, slug);
        if (!vod) return;

        let episodeIndex = player.playlist.indexOf(vod);
        if (episodeIndex >= 0) {
          player.gotoTrack(episodeIndex);
        }
      }
    });

    //listen to external switchLiveChannel event
    document.addEventListener(window.deltatre.player.events.switchLiveChannel, function (e) {
      let slug = e.channelSlug;
      if (
        slug &&
        player.playlist &&
        player.playlist.length > 0 &&
        (dom('#prime-video-player-container').length === 0 ||
          dom('#prime-video-player-container').hasClass('d-none')) &&
        (dom('#prime-playlist-item-container').length === 0 ||
          dom('#prime-playlist-item-container').hasClass('d-none'))
      ) {
        let vod = findVodInPlaylistBySlug.call(player, slug);
        if (!vod) return;
        let episodeIndex = player.playlist.indexOf(vod);
        if (episodeIndex >= 0) {
          player.gotoTrack(episodeIndex);
        }
      }
    });

    document.addEventListener(window.deltatre.player.events.stopPlayer, function () {
      player.playerInstance.stop();
    });

    document.addEventListener(window.deltatre.player.events.pausePlayer, function () {
      player.playerInstance.pause();
    });

    document.addEventListener(window.deltatre.player.events.startPlayer, function () {
      player.playerInstance.play();
    });

    //check if video slug passed with querystring
    let epgSlug =
      window.deltatre.ocsvp.playlist.activeVideo ||
      window.deltatre.utils.code.getQueryStringValue('slug');
    if (epgSlug) {
      let vod = findVodInPlaylistBySlug.call(this, epgSlug);
      if (vod) {
        if (
          vod.metaData &&
          vod.metaData.videoType &&
          vod.metaData.videoType.toUpperCase() === 'LINEAR'
        ) {
          raiseSwitchLiveChannelEvent(epgSlug);
        } else {
          raiseSwitchVideoEvent(epgSlug);
        }
      }
    }

    tracking.track('Video Loaded', regwallTracking.defaultTrackingProps());

    dom(document).on('click', 'button.theo-close-button.theo-pip-close', function () {
      dom(playerSelectors).addClass('persist-hide-pip');
    });

    if (opt?.isSingleVodAutoplay) {
      const observerOptions = {
        rootMargin: '0px',
        threshold: 1,
      };

      const observer = new IntersectionObserver((entries, obs) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.playerInstance.play();
            observer.unobserve(this.target);
          }
        });
      }, observerOptions);

      observer.observe(this.target);
    }

    const event = new CustomEvent('player-initialized');
    window.dispatchEvent(event);
  };

  let onEnded = function (eventData) {
    //CountDown
    countdown.setContent(eventData, this);

    //Playlist CountDown
    countdown.setCountDown(this);

    tracking.track('Video Content Completed', regwallTracking.defaultTrackingProps());
    tracking.track('Video Playback Completed', regwallTracking.defaultTrackingProps());
    tracking.stopTimedTracking();
    tracking.stopWatchTimeCounter();
    countPlay = 0;
  };

  let onError = function (eventData) {
    tracking.track('Video Playback Interrupted', regwallTracking.defaultTrackingProps());

    //CountDown
    countdown.setContent(eventData, this);

    //Playlist CountDown
    countdown.setCountDown(this);

    if (window.deltatre?.d3vp?.config?.enableTheoPlayerPreloadPoster == 'true') {
      switchTheoPlayerDefaultPoster(false);
    }
  };

  let onPause = function (eventData) {
    if (this.currentContent.active) {
      this.currentContent.active = false;
    }
    videoProps = tracking.updateVideoProps(this, videoProps);

    if (this.playerInstance.ads.playing === true) {
      tracking.track('Video Ad Clicked', regwallTracking.defaultTrackingProps(false));
    }

    //Added this case because onPause fired also
    //at the beginning of video on index change
    if (eventData.currentTime > 0) {
      tracking.track('Video Content Paused', regwallTracking.defaultTrackingProps(false));
      tracking.track('Video Playback Paused', regwallTracking.defaultTrackingProps(false)); //DDnA to confirm removal (see B2P-893)
    }

    tracking.stopTimedTracking();
    tracking.stopWatchTimeCounter();
  };

  let onPlay = function (eventData) {
    if (window.deltatre?.d3vp?.config?.enableTheoPlayerPreloadPoster == 'true') {
      // If player is playing don't switch poster
      if (this.playerInstance.currentTime == 0) {
        switchTheoPlayerDefaultPoster(true);
      }

      hideTheoPlayerPreloadPoster();
    }

    // Always set autoplay when user play a video for the first time
    if (
      !this?.options?.isSingleVod &&
      window.deltatre.d3vp.d3vpCore.adaptedPlayers[0].playerInstance.autoplay === false
    ) {
      window.deltatre.d3vp.d3vpCore.adaptedPlayers[0].playerInstance.autoplay = true;
    }

    this.currentContent.active = true;
    videoProps = tracking.updateVideoProps(this, videoProps);

    if (
      !!this.currentContent &&
      !!this.currentContent.additionalOptions &&
      !!this.currentContent.additionalOptions.mpxSelectorUrl &&
      countPlay === 0
    ) {
      //MPX callback
      try {
        let xhr = new XMLHttpRequest();
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            //console.log('success!', xhr
            let data = JSON.parse(xhr.responseText);
            if (!!data && !!data.entries && data.entries.length > 0) {
              let _entry = data.entries[0];
              if (!!_entry && !!_entry.plmedia$publicUrl) {
                try {
                  let _xhr = new XMLHttpRequest();
                  _xhr.onload = function () {
                    if (_xhr.status >= 200 && _xhr.status < 300) {
                      //callback done
                    } else {
                      console.error('mpx callback failed');
                    }
                  };
                  _xhr.open('GET', _entry.plmedia$publicUrl + '?formats=m3u');
                  _xhr.send();
                } catch (err) {
                  console.error('mpx callback failed - unexpected error');
                  console.error(err.message);
                }
              }
            }
          } else {
            console.error('mpx callback failed');
          }
        };
        xhr.open('GET', this.currentContent.additionalOptions.mpxSelectorUrl);
        xhr.send();
      } catch (err) {
        console.error('mpx selector url call failed - unexpected error');
        console.error(err.message);
      }
    }

    // Don't set video mark if video is behind regwall
    if (!isVodBehindRegwall()) {
      videomark.setVideoMark(eventData, this);
    }

    //Video Mark
    videomark.setPosition(this);

    //Captions
    audiocaptions.manageCaptions(this);

    // stop other playing content pip
    window.deltatre.d3vp.d3vpCore.adaptedPlayers.forEach((el) => {
      if (el.target !== this.target && el.currentContent.active) {
        el.currentContent.active = false;
        el.playerInstance.presentationMode = 'inline';
        el.pause();
      }
    });

    // Send event to playlist to show the "now playing" label
    const playEvent = new CustomEvent('playlist-playing');
    document.dispatchEvent(playEvent);
  };

  let onPlaying = function () {
    if (window.deltatre?.d3vp?.config?.enableTheoPlayerPreloadPoster == 'true') {
      switchTheoPlayerDefaultPoster(false);
    }

    const handleContentPlay = () => {
      if (this.playerInstance.ads.playing === false) {
        countPlay = tracking.handlerOnContentPlay(countPlay, this?.options?.autoplay);
      }
    };

    // Set analytics data in case is not populated by taking it from our API
    const setAnalyticsData = () => {
      if (!window.deltatre.utils.code.findAndGetInObject(this.currentContent, 'slug')) {
        return Promise.resolve();
      }
      let url = `/api/v1/d3vp/vod/syndicated/detail/slug/${this.currentContent.slug}`;

      return fetch(window.deltatre.http.getAndSetLanguageUrl(url))
        .then((response) => response.json())
        .then(_.first)
        .then((data) => {
          this.currentContent.analyticsData = window.deltatre.utils.code.findAndGetInObject(
            data,
            'analyticsData',
          );
          tracking.setContent(this.currentContent);
        });
    };

    this.currentContent.analyticsData
      ? handleContentPlay()
      : setAnalyticsData().then(handleContentPlay);
  };

  let onPresentationModeChange = function (_eventData) {
    if (
      !this.currentContent.active &&
      this.playerInstance.presentationMode === 'picture-in-picture'
    ) {
      this.playerInstance.presentationMode = 'inline';
    }
  };

  let onRateChange = function (_eventData) {
    tracking.track('Video Playback Rate Changed', regwallTracking.defaultTrackingProps());
  };

  let onSeeking = function (_eventData) {
    videoProps = tracking.updateVideoProps(this, videoProps);
    tracking.track('Video Playback Seek Started', regwallTracking.defaultTrackingProps());
  };

  let onTimeUpdate = function (eventData) {
    if (this.playerInstance.ads.playing === false) {
      videoProps = tracking.updateVideoProps(this, videoProps);
    }

    //Video Mark management
    // Don't set video mark if video is behing regwall
    if (!isVodBehindRegwall()) {
      videomark.setVideoMark(eventData, this);
    }

    const event = new CustomEvent('regwall-video-progress', {detail: {data: eventData}});
    window.dispatchEvent(event);
  };

  let setRegwallData = function (data, slug) {
    if (data?.isRegwall) {
      setRegwallGlobalObject(data);

      document.dispatchEvent(
        new CustomEvent('on-player-regwall-match', {detail: {data: slug}}),
      );
    }
  };

  let setRegwallGlobalObject = function (data) {
    window.deltatre = window.deltatre || {};
    window.deltatre.regwallvod = window.deltatre.regwallvod || {};

    window.deltatre.regwallvod.endpoint = data.content.endpoint;
    window.deltatre.regwallvod.mainSelector = data.content.mainSelector;
    window.deltatre.regwallvod.modalSelector = data.content.modalSelector;
    window.deltatre.regwallvod.eventName = data.content.eventName;
    window.deltatre.regwallvod.tier1Match = data.content.tier1Match;
    window.deltatre.regwallvod.tier2Match = data.content.tier2Match;
    window.deltatre.regwallvod.disableAds = data.content.disableAds;
    window.deltatre.regwallvod.playTooltip = data.content.playTooltip;
    window.deltatre.regwallvod.signupflow = data.content.signupflow;
    window.deltatre.regwallvod.userSigned = data.content.userSigned;

    window.deltatre.regwallvod.vod = window.deltatre.regwallvod.vod || {};

    window.deltatre.regwallvod.vod.duration = data.content.vod.duration;
    window.deltatre.regwallvod.vod.slug = data.content.vod.slug;
    window.deltatre.regwallvod.vod.videoID = data.content.vod.entityId;
    window.deltatre.regwallvod.vod.localizedVideoID = data.content.vod.localizedVideoID;
    window.deltatre.regwallvod.vod.tags = data.content.vod.tags;
    window.deltatre.regwallvod.vod.wonId = data.content.vod.wonId;
    window.deltatre.regwallvod.vod.type = data.content.vod.type;
    window.deltatre.regwallvod.vod.thumbnail = data.content.vod.thumbnail;
  };

  let fetchRegwallData = async function (slug) {
    const isLive = window.deltatre?.regwallvod?.isLive ?? 'false';
    const lang = window.deltatre.utils.code.getLanguageFromHtml();

    // Used DdnaConfiguration to know if in embedded widget, to avoid adding a regwall specific one for the same purpose, with same value
    const url = window.deltatre.isEmbedded
      ? window.deltatre?.config?.regwall?.urlEmbedded
      : window.deltatre?.config?.regwall?.url;

    const result = await fetch(
      url.replace('{lang}', lang).replace('{slug}', slug).replace('{islive}', isLive),
    );

    const data = await result?.json();

    if (data?.modules.length > 0 && data.modules[0].content.length > 0) {
      const regwallData = data.modules[0].content[0];

      if (regwallData?.isRegwall) {
        setRegwallData(regwallData, slug);
      }

      return regwallData;
    }

    return undefined;
  };

  let onIndexChange = function (index, content) {
    let playerOnChange = this;

    dom(document).ready(async function () {
      if (!content) return;

      let isRegwall = false;

      if (window.deltatre?.regwallvod?.vod?.slug != content.slug) {
        const result = await fetchRegwallData(content.slug);
        isRegwall = result?.isRegwall;
      }

      if (!isRegwall) {
        //Swiper carousel added from .cshtml or reactor
        let swiper = swiperCarouselInstance || d3.swiperReact;

        if (swiper) {
          let $divVideoPlayerPlaylist = dom('.playlist');

          let $nowpl = $divVideoPlayerPlaylist.find('.playlist--episodes .current');

          let $nextpl = $divVideoPlayerPlaylist.find('.episode[data-episode-index=' + index + ']');

          swiper.movetoIndex(index);

          if ($nowpl.length > 0 && $nextpl.length > 0) {
            $nowpl
              .first()
              .detach()
              .prependTo('.episode[data-episode-index=' + index + '] .article--img');
          }
        }

        videoProps = {};
        tracking.setVideo(videoProps);
        tracking.setContent(content);
        advertising.setContent(content);

        if (!isVodBehindRegwall()) {
          videomark.setVideoMarkKey(playerOnChange, content.slug);
        }

        //Captions
        audiocaptions.manageCaptions(playerOnChange);

        countPlay = 0;
        tracking.stopTimedTracking();
        tracking.stopWatchTimeCounter();
      }
    });
  };

  let onAudioTrackChange = function (audioTrack) {
    videoProps.audio_language = (audioTrack && audioTrack.language) || null;
    videoProps = tracking.updateVideoProps(this, videoProps);
    tracking.track('audioTrackChanged', regwallTracking.defaultTrackingProps(false));

    audiocaptions.manageAudioTracks(audioTrack);
  };

  let onSubtitleTrackChange = function (subtitleTrack) {
    videoProps.subtitle_language = (subtitleTrack && subtitleTrack.language) || null;
    videoProps = tracking.updateVideoProps(this, videoProps);
    tracking.track('Subtitles');

    if (!this.playerInstance.ended) {
      audiocaptions.resetCaptionsCodeAndLabel(subtitleTrack);
      audiocaptions.setCaptionStorage(subtitleTrack);
    }
  };

  const isVodBehindRegwall = function () {
    return (
      !userSignedIn &&
      (window.deltatre.regwallvod?.tier1Match || window.deltatre.regwallvod?.tier2Match)
    );
  };

  const isGoogleDaiEnabled = function () {
    return window.deltatre.utils.code.isNullOrWhiteSpace(
      d3.utils.dom.getMetaTagValue(d3.config.metaTags.disableGoogleDai),
    );
  };

  const regwallAdvDisabled = function () {
    return window.deltatre?.regwallvod?.signupflow && window.deltatre?.regwallvod?.disableAds;
  };

  const switchVideoHandler = function (e) {
    let slug = e.slug ?? e.detail.slug;
    d3.ocsvp.playlist.activeVideo = slug;

    let player = window.deltatre.d3vp.d3vpCore.adaptedPlayers[0];
    if (
      slug &&
      player &&
      player.playlist &&
      player.playlist.length > 0 &&
      (dom('#prime-video-player-container').length === 0 ||
        dom('#prime-video-player-container').hasClass('d-none')) &&
      (dom('#prime-playlist-item-container').length === 0 ||
        dom('#prime-playlist-item-container').hasClass('d-none'))
    ) {
      let vod = findVodInPlaylistBySlug.call(player, slug);
      if (!vod) return;

      //check bug-overlay
      let videoContainer = document.querySelector('[data-bug-overlay-tag]');
      if (videoContainer) {
        let overlayTag = videoContainer.dataset.bugOverlayTag;
        let tags = JSON.parse(vod.analyticsData.tag);
        let isTagged = tags.findIndex((f) => f.slug === overlayTag) >= 0;
        if (isTagged && !videoContainer.classList.contains('-bug-overlay')) {
          //Add the overlay
          videoContainer.classList.add('-bug-overlay');
        } else if (!isTagged && videoContainer.classList.contains('-bug-overlay')) {
          //Remove the overlay
          videoContainer.classList.remove('-bug-overlay');
        }
      }

      let episodeIndex = player.playlist.indexOf(vod);
      if (episodeIndex >= 0) {
        player.gotoTrack(episodeIndex);

        if (dom('.player-container .video-title').length) {
          dom('.player-container .video-title').html(vod.title);
        }
      }
    }
  };

  const switchTheoPlayerDefaultPoster = function (show) {
    const posterDiv = document.querySelector('.theoplayer-poster');

    if (posterDiv) {
      posterDiv.style.visibility = show ? 'visible' : 'hidden';
    }
  };

  const hideTheoPlayerPreloadPoster = function () {
    const poster = document.querySelector('.video-player-container__poster');

    if (poster) {
      poster.classList.add('d-none');
    }
  };

  d3.ocsvp = d3.ocsvp || {};

  d3.ocsvp.playlist = d3.ocsvp.playlist || {};

  d3.ocsvp.playlist.handlers = d3.ocsvp.playlist.handlers || {};

  d3.ocsvp.playlist.handlers.switchVideoHandler = switchVideoHandler;

  function getEventHandlers() {
    return {
      d3vp: {
        contentready: onContentReady,
        playercreated: onPlayerCreated,
      },
      theoplayer: {
        indexchange: onIndexChange,
        subtitletrackchange: onSubtitleTrackChange,
        audiotrackchange: onAudioTrackChange,
      },
      player: {
        ended: onEnded,
        error: onError,
        pause: onPause,
        play: onPlay,
        playing: onPlaying,
        presentationmodechange: onPresentationModeChange,
        ratechange: onRateChange,
        seeking: onSeeking,
        timeupdate: onTimeUpdate,
      },
    };
  }
})(window, window.deltatre || {});
