const parseHTML = (textHTML = '') => {
  const parser = new DOMParser();

  const parsedDom = parser.parseFromString(textHTML, 'text/html');

  const scripts = parsedDom.getElementsByTagName('script');

  for (const node of scripts) node.dataset.importedBy = 'd3dom';

  document.head.append(...scripts);

  return textHTML;
};

export const ajaxDefaultSettings = {
  method: 'GET',
  async: true,
  contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
  converters: {
    'text/html': parseHTML,
    html: parseHTML,
    'text/json': (text) => JSON.parse(text),
    'application/json': (text) => JSON.parse(text),
    json: (text) => JSON.parse(text),
    script: (script) => {
      eval(script);

      return script;
    },
  },
  crossDomain: 'auto',
  xhrFields: {},
};
