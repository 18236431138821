import dom from '../../scripts/components/utils/dom/index';

dom(document).ready(() => {
  // v2\originalsDetail.cshtml
  setAuthenticatedContent();
});

function checkSelector(selector) {
  return window.deltatre.utils.dom.checkSelector(selector);
}

function setAuthenticatedContent() {
  if (checkSelector('#prime-video-box-container')) {
    const ptlogo = localStorage.getItem('primeTimeLogoPath');
    const ptname = localStorage.getItem('primeTimeProviderName');

    if (
      ptlogo &&
      document.cookie.indexOf('primeTimeAuthenticated=') != -1 &&
      document.cookie.indexOf('primeTimeAuthenticated=;') == -1
    ) {
      if (document.getElementById('PrimeTimeLogo')) {
        document.getElementById('PrimeTimeLogo').src = ptlogo;
      }
      if (document.getElementById('PrimeTimeName')) {
        document.getElementById('PrimeTimeName').innerText = ptname;
      }

      const primeTimeProviderLabel = document.getElementById('primetimeProvider');
      if (primeTimeProviderLabel) {
        primeTimeProviderLabel.innerText = dom('#authenticatedContentLabel').val();

        if (
          window.location.href.indexOf('/live/') > -1 ||
          window.location.href.indexOf(window.deltatre.linkRulesConfig.events) > -1
        ) {
          primeTimeProviderLabel.innerText = `${
            primeTimeProviderLabel.innerText
          } ${localStorage.getItem('primeTimeProviderName')}`;
        }
      }

      const primeTimePlusLabel = document.getElementById('plusAuthenticatedLabel');
      if (primeTimePlusLabel) {
        primeTimePlusLabel.classList.remove('d-none');
      }

      document.getElementById('prime-video-box-container').classList.remove('d-none');

      document.getElementById('video-player-container').classList.remove('d-none');

      if (document.getElementById('prime-video-player-container')) {
        document.getElementById('prime-video-player-container').classList.add('d-none');
      }

      if (document.getElementById('prime-playlist-item-container')) {
        document.getElementById('prime-playlist-item-container').classList.add('d-none');
      }
    }
  }
}

window.showMoreBioCallback = () => {
  const bioElement = dom('[data-desc-expander]');
  bioElement.toggleClass('-expanded');

  const completeBioElement = dom('.content-bio--expand');
  const ellipsisBlock = dom('.more-bio-ellipsis');

  const tagsList = dom('[data-video-tags-list]');
  tagsList.toggleClass('-full-tags');

  if (completeBioElement.hasClass('hidden')) {
    completeBioElement.removeClass('hidden');
    ellipsisBlock.hide();
  } else {
    completeBioElement.addClass('hidden');
    ellipsisBlock.show();
  }
};
