export function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    let context = this;
    let args = arguments;

    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export function attrToBoolean(value) {
  if (typeof value !== 'string') return false;

  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
    case '':
      return true;
    case 'false':
    case 'no':
    case '0':
      return false;
    default:
      return Boolean(value);
  }
}

export function attrToJson(value, type) {
  let json = null;
  try {
    json = JSON.parse(value);
  } catch (error) {
    console.warn(`LitElement::Attributes: couldn't decode ${type.name} as JSON`);
  }

  return json;
}

export function getAngularJsService(serviceName, element) {
  return window.angular ? window.angular.element(element).injector().get(serviceName) : null;
}

export function getUtcString(dateStr, subtractTimezoneOffset = true) {
  if (!Date.parse(dateStr)) return null;

  let dt = new Date(dateStr);
  if (subtractTimezoneOffset) dt = new Date(dt.getTime() - dt.getTimezoneOffset() * 60000);

  return dt.toUTCString();
}

export function getClosestSibling(element, direction = 'downwards') {
  if (direction !== 'downwards' && direction !== 'upwards')
    throw new Error('The direction parameter must be equal to "downwards" or "upwards"');

  let sibling = null;

  if (element) {
    sibling = direction === 'downwards' ? element.nextSibling : element.previousSibling;

    while (sibling && sibling.nodeType !== 1) {
      sibling = direction === 'downwards' ? sibling.nextSibling : sibling.previousSibling;
    }
  }

  return sibling;
}

export function swapElements(array, x, y) {
  [array[x], array[y]] = [array[y], array[x]];

  return array;
}

export function autoResizeElement(el) {
  if (el) {
    el.style.cssText = 'height:auto';
    el.style.cssText = 'height:' + el.scrollHeight + 'px';
  }
}

export function randomString(length = 4) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}
