import { readMime } from './readMime';

const isStatusOk = (status) => status >= 200 && status <= 299;

export class D3Xhr {
  static getResponse = (xhr, options) => {
    if (!xhr.response) return undefined;

    const contentType = xhr.getResponseHeader('content-type');

    const mimeType = options.dataType || readMime(contentType);

    if (options.converters && options.converters[mimeType])
      return options.converters[mimeType](xhr.response, options);

    console.warn('D3 DOM AJAX: No valid converter found');

    return xhr.response;
  };

  static getResolver = (xhr, options) => (resolve, reject) => {
    try {
      xhr.send(options.data);

      const checkResponse = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;

        if (isStatusOk(xhr.status)) resolve(D3Xhr.getResponse(xhr, options));
        else reject(D3Xhr.getResponse(xhr, options));
      };

      if (xhr.readyState === XMLHttpRequest.DONE) {
        checkResponse();

        return;
      }

      xhr.onreadystatechange = checkResponse;
    } catch (e) {
      console.error('D3 DOM AJAX ERROR:', e);
      reject(e);
    }
  };

  constructor(xhr, options = {}) {
    this.xhr = xhr;
    this.options = options;

    if (options.async) this.setupAsync();
    else this.setupSync();
  }

  setupSync = () => {
    const xhr = this.xhr;
    const options = this.options;

    try {
      xhr.send(options.data);

      const isOk = isStatusOk(xhr.status);

      this.response = D3Xhr.getResponse(xhr, options);

      if (!isOk) this.error = xhr.statusText;

      if (isOk && options.success)
        options.success.call(options.context, this.response, xhr.statusText, xhr);

      if (!isOk && options.error) options.error.call(options.context, xhr.status, xhr.statusText);
    } catch (e) {
      if (options.error) options.error.call(options.context, xhr.status, xhr.statusText, e);
      this.error = e;
    }
  };

  setupAsync = () => {
    const xhr = this.xhr;
    const options = this.options;

    this.promise = new Promise(D3Xhr.getResolver(xhr, options));

    if (options.success)
      this.then((data) => options.success.call(options.context, data, xhr.statusText, xhr));

    if (options.error)
      this.catch((error) => options.error.call(options.context, xhr.status, xhr.statusText, error));
  };

  then = (onFulfilled, onRejected) => {
    this.promise = this.promise.then(
      onFulfilled && ((response) => onFulfilled(response, this.xhr.statusText, this.xhr)),
      onRejected && ((error) => onRejected(this.xhr.status, this.xhr.statusText, error)),
    );

    return this.promise;
  };

  done = (onFulfilled) => {
    this.promise = this.promise.then(
      onFulfilled && ((response) => onFulfilled(response, this.xhr.statusText, this.xhr)),
    );

    return this.promise;
  };

  catch = (onFail) => {
    this.promise = this.promise.catch(onFail);

    return this.promise;
  };

  fail = this.catch;

  finally = (onFinally) => {
    this.promise = this.promise.finally(onFinally);

    return this.promise;
  };

  always = this.finally;
}
