class UrlHelperService {
  static isDisplayAsWebViewEnabled() {
    const params = new URLSearchParams(window.location.search);
    const displayAsWebView = params?.get('displayAsWebView') ? params.get('displayAsWebView') : '';

    return displayAsWebView && displayAsWebView?.toLowerCase() === 'true';
  }
}

export default UrlHelperService;
