import '@d3/ocs-d3vp/dist/scripts/d3vp/d3vpCoreBundle';
import '@d3/ocs-d3vp/dist/scripts/d3vp/d3vpTheoplayerAdapterBundle';

import './videoTracking.js';
import './videoAdvertising.js';
import './videoAudioCaptions.js';
import './videoMark.js';
import './videoCountdown.js';
import './videoplayerPageready.js';
import '../main/pageready.video.js';
