export function slideDown(duration = 400, onComplete = null) {
  this.elementList.forEach((element) => handleSlideDown(element, duration, onComplete));
}

const handleSlideDown = (element, duration, onComplete) => {
  element.style.display = 'block';
  const height = getComputedStyle(element).height;
  element.style.height = '0';
  element.style.minHeight = '0';
  element.style.transition = `min-height ${duration}ms ease-in-out`;

  setTimeout(() => (element.style.minHeight = height), 1);

  setTimeout(() => {
    element.style.display = '';
    element.style.height = '';
    element.style.minHeight = '';
    element.style.transition = '';
    if (onComplete) onComplete();
  }, duration + 1);
};
