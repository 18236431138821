/**
 * @name eventManager
 * @param event Name of event.
 * @description  Bind an event handler to the passed JavaScript event, or trigger that event on an element.
 */

export function eventManager(event, ...params) {
  if (!event) {
    console.warn('D3 DOM: Missing parameters');

    return;
  }

  let handler = null;
  let data = null;
  if (params.length === 2) handler = params[1];
  if (params.length === 3) {
    data = params[1];
    handler = params[2];
  }

  if (!handler) {
    this.trigger(event);

    return this;
  }

  this.on(event, data, handler);

  return this;
}
