import { isObject } from './isObject';

export const extendObj = (...params) => {
  const deepCopy = params[0] === true;
  const target = deepCopy ? params[1] : params[0];
  const obj = deepCopy ? params.slice(2) : params.slice(1);

  const source = obj.reduce((acc, curr) => ({ ...acc, ...curr }), {});

  if (deepCopy) deepCopyObject(target, source);
  else Object.assign(target, source);

  return target;
};

const deepCopyObject = (target, source) => {
  Object.entries(source).forEach(([key, value]) => {
    const targetElement = target[key];
    if (isObject(targetElement) && isObject(value)) deepCopyObject(targetElement, value);
    // Get a copy
    else target[key] = JSON.parse(JSON.stringify(value));
  });
};
