const analytics = {
  getAnalyticsObject: async (counterValue = 20) => {
    const resolver = function (resolve, reject) {
      let counter = 0;
      let timer = setInterval(() => {
        counter++;
        if (counter > counterValue) {
          clearInterval(timer);
          reject('window.analytics not initialised');
        }
        if (window.analytics !== undefined && window.analytics.user !== undefined) {
          clearInterval(timer);
          resolve(window.analytics);
        }
      }, 200);
    };

    return new Promise(resolver);
  },
  // Return 'blank space' for anonymous id when tracking consent is not given
  getAnonymousId: async function () {
    return window.ddnawrapper?.consent?.isTrackingConsentGiven()
      ? await window.ddnawrapper?.user?.anonymousId()
      : ' ';
  },
};

export default analytics;
