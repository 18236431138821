/**
 * @description A map of events based on type. Necessary to trigger events correctly.
 */
export const eventMap = {
  click: MouseEvent,
  dblclick: MouseEvent,
  mouseup: MouseEvent,
  mousedown: MouseEvent,
  keydown: KeyboardEvent,
  keyup: KeyboardEvent,
  keypress: KeyboardEvent,
};
