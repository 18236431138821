import {randomString} from '../../webcomponents/utils';
import UrlHelperService from '../utils/url-helper-service';

export const REGWALL_ID_LENGTH = 26;
export const REGWALL_SHOWN_TRACKING_EVENT = 'Reg Wall Shown';
export const REGWALL_CLICKED_TRACKING_EVENT = 'Reg Wall Clicked';
export const REGWALL_SHOWN_CLOSE = 'Reg Wall Close';
export const REGWALL_VIDEO_PAGE = 'Reg Wall Video Page';

export class RegwallTracking {
  isRegwallVodMatch() {
    if (UrlHelperService.isDisplayAsWebViewEnabled()) {
      return false;
    }

    return window.deltatre.regwallvod?.tier1Match || window.deltatre.regwallvod?.tier2Match;
  }

  isRegwallSignupProcess() {
    if (window.deltatre?.regwallvod?.userSigned) {
      return false;
    }

    return !window.deltatre?.regwallvod?.signupflow;
  }

  defaultTrackingProps(useInteraction = true) {
    let props = {
      is_reg_wall_video: this.isRegwallVodMatch(),
      is_reg_wall_signup: this.isRegwallSignupProcess(),
    };

    if (useInteraction) {
      props = Object.defineProperty(props, 'nonInteraction', {
        value: 1,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    }

    return props;
  }

  getRegwallRandomId() {
    return randomString(REGWALL_ID_LENGTH);
  }

  specificRegwallProps(event, isTimebomb = false, timebombPeriod = 0, popupId = '') {
    let specificProps = {
      is_reg_wall_video: this.isRegwallVodMatch(),
      is_reg_wall_signup: this.isRegwallSignupProcess(),
      reg_wall_type: isTimebomb ? 'timebomb' : 'modal window',
      video_product_id: window.deltatre.regwallvod.vod.wonId,
      video_type: window.deltatre.regwallvod.vod.type,
      reg_wall_id: popupId,
    };

    switch (event) {
      case REGWALL_CLICKED_TRACKING_EVENT:
      case REGWALL_SHOWN_CLOSE:
      case REGWALL_SHOWN_TRACKING_EVENT:
        specificProps = Object.defineProperty(specificProps, 'timebomb_period', {
          value: timebombPeriod,
          writable: true,
          configurable: true,
          enumerable: true,
        });

        break;
      default:
        break;
    }

    return specificProps;
  }
}
