/*
 * Handles tracking of THEOplayer events
 */
(function (window, d3) {
  'use strict';

  // define utils
  let utils = d3.d3vp && d3.d3vp.utils;

  // check dependencies
  if (!utils) {
    throw new Error('D3vp::utils missing');
  }

  // namespace
  d3.ocsvp = d3.ocsvp || {};
  d3.ocsvp.advertising = d3.ocsvp.advertising || {};
  let namespace = d3.ocsvp.advertising;

  // tracking object properties
  let contentProps = {};

  // NOTE: countrySlug missing from API call
  namespace.setContent = function (props) {
    let obj = {};
    obj.associationSlug = (props.metaData && props.metaData.associationSlug) || null;
    obj.athlete = (props.metaData && props.metaData.athleteSlug) || null;
    obj.brand_title = (props.metaData && props.metaData.sponsor) || null;
    obj.content_release_date_local_utc = (props.metaData && props.metaData.contentDate) || null;
    obj.content_title = props.title || null;
    obj.country = (props.metaData && props.metaData.countrySlug) || null;
    obj.discipline = (props.metaData && props.metaData.disciplineName) || null;
    obj.episode_number = (props.metaData && props.metaData.episodeNumber) || null;
    obj.episode_synopsis = props.description || null;
    obj.episode_title = props.title || null;
    obj.episode_uid = props.entityId || null;
    obj.event = (props.metaData && props.metaData.eventName) || null;
    obj.image_url = props.placeholder || null;
    obj.language = (props.metaData && props.metaData.languageCode) || null;
    obj.season_name = (props.metaData && props.metaData.seasonName) || null;
    obj.seriesTitle = (props.metaData && props.metaData.seriesTitle) || null;
    obj.show_synopsis = props.description || null;
    obj.slug = props.slug || null;
    obj.sponsor = (props.metaData && props.metaData.sponsor) || null;
    obj.sport = (props.metaData && props.metaData.disciplineName) || null;
    obj.tag = (props.metaData && props.metaData.tags) || null;
    obj.video_type = (props.metaData && props.metaData.videoType) || null;
    obj.video_url = props.src || null;
    obj.channelId = (props.additionalOptions && props.additionalOptions.channelId) || null;
    obj.commercial = (props.additionalOptions && props.additionalOptions.commercial) || true;
    obj.daiKey = (props.additionalOptions && props.additionalOptions.daiKey) || null;
    obj.dfpAccountDaiKey =
      (props.additionalOptions && props.additionalOptions.dfpAccountDaiKey) || null;
    obj.program_id = (props.metaData && props.metaData.programId) || null;
    obj.series_id = (props.metaData && props.metaData.seriesId) || null;
    obj.season_bucket_id = (props.metaData && props.metaData.seasonBucketId) || null;
    obj.noc_id = (props.metaData && props.metaData.nocId) || null;
    obj.series_title = (props.metaData && props.metaData.seriesTitle) || null;

    contentProps = obj;
  };

  // get DFP VAST url
  namespace.getVastTag = function (content) {
    let _content = content || contentProps;
    let iu = getIu(_content);
    let tag = null;
    if (iu && window.DfpConfiguration.DfpVastBaseUrl) {
      let _cfg = window.DfpConfiguration;
      tag =
        _cfg.DfpVastBaseUrl +
        '?correlator=[timestamp]&env=vp&gdfp_req=1&output=xml_vast3&unviewed_position_start=1';

      //add url
      tag = tag + '&url=' + encodeURI(window.location.href.replace('#amp=1', ''));

      //add description
      tag = tag + '&description_url=' + encodeURI(window.location.href.replace('#amp=1', ''));

      //add ui
      if (
        (_content.hasOwnProperty('commercial') && !_content.commercial) ||
        (window.DfpConfiguration.hasOwnProperty('DfpCommercial') &&
          window.DfpConfiguration.DfpCommercial === 'false')
      ) {
        iu =
          '/' +
          window.DfpConfiguration.DfpNetworkId +
          '/' +
          (window.DfpConfiguration.DfpSection || 'olympicchannel.video') +
          '/noads';
      }
      tag = tag + '&iu=' + iu;

      //add preferred size
      tag = tag + '&sz=640x480';

      //add ad_rule
      tag = tag + '&ad_rule=1';

      tag =
        tag +
        '&cmsid=' +
        (_content.video_type && _content.video_type.toLowerCase() != 'vod'
          ? window.DfpConfiguration.DfpCmsidEpg || '2482058'
          : window.DfpConfiguration.DfpCmsidVod || '2482058');

      //add vid
      tag = tag + '&vid=' + _content.slug;

      //add cust params TODO
      tag = tag + '&cust_params=' + getCustParams(_content);

      tag = addNonPersonalizedAdsParam(tag);
    }

    return tag;
  };

  namespace.setGoogleDAI = function (player) {
    try {
      if (
        !window.deltatre.utils.code.isNullOrWhiteSpace(contentProps.dfpAccountDaiKey) &&
        !window.deltatre.utils.code.isNullOrWhiteSpace(contentProps.daiKey)
      ) {
        player.source.sources.ssai = {
          integration: 'google-dai',
          apiKey: contentProps.dfpAccountDaiKey,
          assetKey: contentProps.daiKey,
          availabilityType: getContentType(player.currentContent),
          contentSourceID: player.currentContent.slug,
          videoID: player.currentContent.slug,
        };
      }
    } catch (err) {
      console.error('setGoogleDAI failed - Unexpected error: ' + err.message);
    }
  };

  // helper functions
  function cleanNullProps(obj) {
    for (let propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  function getUserAuthenticated() {
    return d3.DtUser && d3.DtUser.check() != null;
  }

  function getMetaContentByName(name, content) {
    let contentChecked = content == null ? 'content' : content;

    return document.querySelector("meta[name='" + name + "']")
      ? document.querySelector("meta[name='" + name + "']").getAttribute(contentChecked)
      : null;
  }

  function getIu(obj) {
    if (!window.DfpConfiguration || !window.DfpConfiguration.DfpNetworkId) return null;
    let _cfg = window.DfpConfiguration;
    let ui = '/' + _cfg.DfpNetworkId;
    //add site
    ui = ui + '/' + (_cfg.DfpSection || 'olympicchannel.video');
    //add top section
    let tops = _cfg.DfpSubsection || 'stories';
    let subs = _cfg.DfpSubsection2 || '';
    if (
      obj &&
      obj.metaData &&
      obj.metaData.channelId &&
      obj.metaData.channelId.toLowerCase == 'nbc'
    ) {
      tops = 'nbclinear';
      subs = obj.metaData.channelId;
    } else if (
      obj &&
      obj.metaData &&
      obj.metaData.channelId &&
      obj.metaData.channelId.toLowerCase == 'oc1'
    ) {
      tops = 'ocslinear';
      subs = obj.metaData.channelId;
    } else if (
      obj &&
      obj.metaData &&
      obj.metaData.channelId &&
      obj.metaData.channelId.toLowerCase != 'oc1' &&
      obj.metaData.channelId.toLowerCase != 'nbc'
    ) {
      tops = 'liveevent';
      subs = obj.metaData.channelId;
    }
    if (tops) {
      ui = ui + '/' + tops;
    }
    //add subsection
    if (subs) {
      ui = ui + '/' + subs;
    }

    return ui;
  }

  function getCustParams(_obj) {
    let pars = '';

    //add language
    pars = pars + 'language_version=' + customParamsReplace(window.DfpConfiguration.DfpLanguage);

    //add environment
    pars = pars + '&environment=' + customParamsReplace(window.DfpConfiguration.DfpEnvironment);

    //add platform
    pars = pars + '&platform=web';

    //add commercial - TODO
    let comm =
      _obj.commercial || (window.DfpConfiguration ? window.DfpConfiguration.DfpCommercial : null);

    if (typeof comm === 'undefined' || (comm && comm != 'false')) {
      pars = pars + '&commercial=true';
    } else {
      pars = pars + '&commercial=false';
    }
    //add sponsor
    let spns =
      _obj.sponsor || (window.DfpConfiguration ? window.DfpConfiguration.DfpSponsor : null);
    if (spns) {
      pars = pars + '&sponsor=' + customParamsReplace(spns);
    }

    //add sport
    if (_obj.metaData && _obj.metaData.disciplineSlug) {
      pars = pars + '&sport=' + customParamsReplace(_obj.metaData.disciplineSlug);
    }

    //add athlete
    let athlete = getMetaContentByName('AthleteSlug');
    if (_obj.metaData && _obj.metaData.athleteSlug) {
      athlete = _obj.metaData.athleteSlug;
    }
    if (athlete) {
      pars = pars + '&athlete=' + customParamsReplace(athlete);
    }

    //add nation
    let nation = getMetaContentByName('CountryName');
    if (_obj.metaData && _obj.metaData.countrySlug) {
      nation = _obj.metaData.countrySlug;
    }
    if (nation) {
      pars = pars + '&nation=' + customParamsReplace(nation);
    }

    //add event
    let event = getMetaContentByName('EventSLug');
    if (_obj.metaData && _obj.metaData.eventSlug) {
      event = _obj.metaData.eventSlug;
    }
    if (event) {
      pars = pars + '&event_name=' + customParamsReplace(event);
    }

    //add federation
    let fed =
      !!_obj.metaData && !!_obj.metaData.associationSlug
        ? _obj.metaData.associationSlug
        : window.DfpConfiguration
        ? window.DfpConfiguration.DfpAssociation
        : null;

    if (fed) {
      pars = pars + '&federation=' + customParamsReplace(fed);
    }

    //add title
    if (_obj && _obj.slug) {
      let trslug = _obj.slug;
      if (trslug.length > 40) {
        trslug = trslug.substring(0, 40);
      }
      pars = pars + '&episode_title=' + customParamsReplace(trslug);
    }

    //add Series/show name
    if (_obj.metaData && _obj.metaData.seriesSlug) {
      pars = pars + '&show_name=' + customParamsReplace(_obj.metaData.seriesSlug);
    }

    //add uId/SkyLarkId
    if (_obj && _obj.entityId) {
      pars = pars + '&uid=' + customParamsReplace(_obj.entityId);
    } else {
      if (_obj.slug) {
        pars = pars + '&uid=' + customParamsReplace(_obj.slug);
      }
    }

    //add content type
    if (!!_obj && !!_obj.metaData && !!_obj.metaData.videoType) {
      pars = pars + '&content_type=' + customParamsReplace(_obj.metaData.videoType.toLowerCase());
    }

    //add channel
    if (_obj.metaData && _obj.metaData.channelId) {
      pars = pars + '&channel=' + customParamsReplace(_obj.metaData.channelId);
    }

    return encodeURIComponent(pars);
  }

  function customParamsReplace(parValue) {
    if (parValue == false) {
      return '';
    }
    parValue = parValue.replace(/["'=!+£*~;^()<>,&, ]/g, '_');

    return parValue;
  }

  function getContentType(content) {
    let contentType = '';

    if (!content.metaData || !content.metaData.videoType) {
      return contentType;
    }

    if (content.metaData.videoType.toUpperCase() === 'EPG') {
      contentType = 'live';
    } else if (content.metaData.videoType.toUpperCase() === 'VOD') {
      contentType = 'vod';
    }

    return contentType;
  }

  function addNonPersonalizedAdsParam(tag) {
    if (window.ddnawrapper?.consent?.isConsentGiven()) {
      let targetingConsent = window.deltatre.tracking.isTargetingCookieEnabled();
      if (!targetingConsent) {
        tag = tag + '&npa=1';
      }
    }

    return tag;
  }
})(window, window.deltatre || {});
