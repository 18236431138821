export function hide(...params) {
  if (!params.length) {
    handleHide.call(this);

    return;
  }

  if (typeof params[0] === 'function') handleHide.call(this, params[0], params[1]);
  else handleHide.call(this, 400, params[0]);
}

function handleHide(duration = 400) {
  const beginningOpacityRule = `1`;
  const endingOpacityRule = `0`;
  const transitionRule = `opacity ${duration}ms ease-in-out`;

  this.element.style.transition = transitionRule;
  this.element.style.opacity = beginningOpacityRule;

  setTimeout(() => {
    this.element.style.opacity = endingOpacityRule;

    setTimeout(() => {
      this.element.style.transition = '';
      this.element.style.opacity = '';
      this.element.style.display = 'none';
    }, duration);
  }, 1);
}

// id="test" style="display: none;"
