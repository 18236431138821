/*
 * Handles tracking of THEOplayer events
 */

import analyticsService from '../components/utils/analytics';
import {RegwallTracking} from '../components/regwall-vod/regwall-tracking';

(function (window, d3) {
  'use strict';

  let countSeconds = 15,
    countMilliSeconds = 15000,
    trackVideoTimedAction = false,
    watchTimeCounter = false;

  let regwallTracking = new RegwallTracking();

  // define utils
  let utils = d3.d3vp && d3.d3vp.utils;

  // check dependencies
  if (!utils) {
    throw new Error('D3vp::utils missing');
  }

  // namespace
  d3.ocsvp = d3.ocsvp || {};
  d3.ocsvp.tracking = d3.ocsvp.tracking || {};
  let namespace = d3.ocsvp.tracking;

  // tracking object properties
  let contentProps = {};
  let videoProps = {};

  function getQueryStringValue(key) {
    const urlParams = new URLSearchParams(window.location.search);
    const queryStringValue = urlParams.get(key);

    return queryStringValue;
  }

  namespace.setContent = function (props) {
    let obj = {};

    if (props) {
      let islive = checkIfContentIsLive(props);

      obj.athlete = (props.analyticsData && props.analyticsData.athlete) || null;
      obj.athlete_slug = (props.analyticsData && props.analyticsData.athlete_slug) || null;
      obj.brand_title = (props.analyticsData && props.analyticsData.brand_title) || null;
      obj.content_id = (props.analyticsData && props.analyticsData.content_id) || null;
      obj.content_release_date_local_utc =
        (props.analyticsData && props.analyticsData.content_release_date_local_utc) || null;
      obj.content_source = (props.analyticsData && props.analyticsData.content_source) || null;
      obj.content_title = (props.analyticsData && props.analyticsData.content_title) || null;
      obj.country = (props.analyticsData && props.analyticsData.country) || null;
      obj.discipline = (props.analyticsData && props.analyticsData.discipline) || null;
      obj.episode_number = (props.analyticsData && props.analyticsData.episode_number) || null;
      obj.episode_synopsis = (props.analyticsData && props.analyticsData.episode_synopsis) || null;
      obj.episode_title = (props.analyticsData && props.analyticsData.episode_title) || null;
      obj.episode_uid = (props.analyticsData && props.analyticsData.episode_uid) || null;
      obj.external_content_source_id = null; //this
      obj.event = (props.analyticsData && props.analyticsData.event) || null;
      obj.image_url = (props.analyticsData && props.analyticsData.image_url) || null;
      obj.implementation_provider =
        (props.analyticsData && props.analyticsData.implementation_provider) || 'deltatre';
      obj.language = (props.analyticsData && props.analyticsData.language) || null;
      obj.noc_id = (props.analyticsData && props.analyticsData.noc_id) || null;
      obj.product_id = (props.analyticsData && props.analyticsData.product_id) || null;
      obj.program_id = (props.analyticsData && props.analyticsData.program_id) || null;
      obj.season_bucket_id = (props.analyticsData && props.analyticsData.season_bucket_id) || null;
      obj.season_name = (props.analyticsData && props.analyticsData.season_name) || null;
      obj.series_id = (props.analyticsData && props.analyticsData.series_id) || null;
      obj.series_title = (props.analyticsData && props.analyticsData.series_title) || null;
      obj.show_synopsis = (props.analyticsData && props.analyticsData.show_synopsis) || null;
      obj.sport = (props.analyticsData && props.analyticsData.sport) || null;
      obj.subdomain = (props.analyticsData && props.analyticsData.subdomain) || 'deltatre';
      obj.tag = (props.analyticsData && props.analyticsData.tag) || null;
      obj.video_type = (props.analyticsData && props.analyticsData.video_type) || null;
      obj.video_player = (props.analyticsData && props.analyticsData.video_player) || null;
      obj.video_url = (props.analyticsData && props.analyticsData.video_url) || null;
      obj.video_id = (props.analyticsData && props.analyticsData.video_id) || null;
      obj.partner_name = getQueryStringValue('partner_name') || null;

      if (islive) {
        obj.airing_id = (props.analyticsData && props.analyticsData.video_id) || null;
      }

      contentProps = obj;
    }
  };
  namespace.setVideo = function (props) {
    videoProps = props;
  };

  // NOTE: nonInteraction handling missing
  namespace.track = function (name, additionalProperties) {
    if (
      d3.utils.code.checkIsNil(additionalProperties) ||
      !d3.utils.code.checkIsObject(additionalProperties)
    ) {
      additionalProperties = {};
    }

    let eventName = name || 'tracked event';
    let deviceProps = getPageProperties();
    let resolution = getScreenResolution();
    let trackedObj = utils.extend(
      {},
      contentProps,
      videoProps,
      deviceProps,
      resolution,
      additionalProperties,
      regwallTracking.defaultTrackingProps(),
    );

    // B2P-3969 - Send Video events into GTM datalayer
    switch (name) {
      case 'Video Content Started':
      case 'Video Content Playing':
      case 'Video Content Completed':
        if (window.dataLayer) {
          window.dataLayer.push({
            event: name,
            content_id: contentProps.content_id,
          });
        }
        break;
      default:
        break;
    }

    // for debug
    // console.log('%cSegment:', 'background-color: gold', eventName, trackedObj

    // call analytics
    if (window.deltatre.tracking) {
      window.deltatre.tracking.track(eventName, trackedObj);
    }
  };

  namespace.startTimedTracking = function (eventName) {
    if (!trackVideoTimedAction) {
      trackVideoTimedAction = setInterval(
        function () {
          let props = {
            seconds: countSeconds,
            nonInteraction: 1,
          };

          this.track(eventName, props);
          countSeconds = countSeconds + countMilliSeconds / 1000;
        }.bind(this),
        countMilliSeconds,
      );
    }
  };

  namespace.startWatchTimeCounter = function () {
    if (!watchTimeCounter) {
      watchTimeCounter = setInterval(
        function () {
          if (videoProps.watch_time == null) {
            videoProps.watch_time = 1;
          } else {
            videoProps.watch_time++;
          }

          videoProps = this.updateVideoProps(this, videoProps);
        }.bind(this),
        1000,
      );
    }
  };

  namespace.stopTimedTracking = function () {
    clearInterval(trackVideoTimedAction);
    trackVideoTimedAction = false;
  };

  namespace.stopWatchTimeCounter = function () {
    clearInterval(watchTimeCounter);
    watchTimeCounter = false;
  };

  namespace.handlerAdBegin = function () {
    setTrackHandler('Video Ad Started');
  };

  namespace.handlerAdEnd = function () {
    setTrackHandler('Video Ad Completed');
  };

  namespace.handlerAdPlaying = function () {
    namespace.track('Video Ad Playing', regwallTracking.defaultTrackingProps(true));
  };

  namespace.handlerAdSkip = function () {
    namespace.track('Video Ad Skipped', regwallTracking.defaultTrackingProps(true));
  };

  namespace.handlerOnContentPlay = function (countPlay, isVideoAutoplay) {
    namespace.startWatchTimeCounter();
    namespace.startTimedTracking('Video Content Playing');
    if (countPlay === 0) {
      if (!isVideoAutoplay) {
        namespace.track('Video Content Play', regwallTracking.defaultTrackingProps(true));
      }

      setTrackHandler('Video Playback Started');
      namespace.track('Video Content Started', regwallTracking.defaultTrackingProps(true));
    } else {
      namespace.track('Video Content Play', regwallTracking.defaultTrackingProps(true));
      namespace.track('Video Content Resumed', regwallTracking.defaultTrackingProps(true));
      namespace.track('Video Playback Resumed', regwallTracking.defaultTrackingProps(true)); //DDnA to confirm removal (see B2P-893)
    }

    countPlay++;

    return countPlay;
  };

  /**
   * Load the Youbora script. Resolve the promise when the script is loaded.
   * @returns {Promise<void>}
   */
  const loadYouboraScript = function (playerOptions) {
    return new Promise((resolve) => {
      const youboraScript =
        playerOptions?.youboraScript ||
        'https://artifact.plugin.npaw.com/artifactory/plugins/js/7.2.9/NpawPlugin.js';

      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', youboraScript);

      document.head.appendChild(script);

      script.addEventListener('load', () => {
        return resolve();
      });
    });
  };

  /**
   * Load and start Youbora.
   * @param {object} player
   * @param {string} youboraAccount
   * @returns {void}
   */
  namespace.setYoubora = function (player, youboraAccount) {
    const startYoubora = () => {
      let currentContent = player.currentContent || null;
      if (window.GlobalConfiguration?.disableYoubora || !youboraAccount || !currentContent) {
        return;
      }

      let islive = checkIfContentIsLive(currentContent);

      let plugin = d3.youboraPlugin ?? new NpawPlugin(youboraAccount);

      plugin?.setAnalyticsOptions({
        'content.isLive': islive,
        'content.title': currentContent.title,
      });

      if (player?.playerInstance) {
        plugin?.registerAdapter(player.playerInstance, '/static/youbora/config.json');
      }
    };

    const checkConsentAndStartYoubora = () => {
      return new Promise((resolve) => {
        if (window.ddnawrapper?.consent?.isPerformanceConsentGiven() === true) {
          loadYouboraScript(player.options).then(() => {
            startYoubora();
            resolve();
          });
        } else {
          resolve();
        }
      });
    };

    if (player.options?.disableYoubora) {
      return;
    }

    if (window.ddnawrapper?.events?.cookieLoadedTriggered) {
      checkConsentAndStartYoubora().catch((err) => console.error(err));
    } else {
      window.addEventListener('cookies-loaded', () => {
        checkConsentAndStartYoubora().catch((err) => console.error(err));
      });
    }
  };

  namespace.setConviva = async function (player, content) {
    if (!content || (window.GlobalConfiguration && window.GlobalConfiguration.disableConviva))
      return;

    const isLive = checkIfContentIsLive(content);
    const userId =
      window.deltatre.tracking.getUserId() || (await analyticsService.getAnonymousId());

    player.source.analytics = [
      {
        integration: 'conviva',
        customerKey: d3.utils.dom.getMetaTagValue(d3.config.metaTags.convivaCustomerKey),
        gatewayUrl: d3.utils.dom.getMetaTagValue(d3.config.metaTags.convivaTouchstoneHost),
        contentMetadata: {
          assetName: content.title || content[0].title,
          live: isLive,
          viewerId: userId,
          applicationName: d3.conviva.metadata.playerValueTheo,
        },
      },
    ];
  };

  namespace.updateVideoProps = function (player, videoProps) {
    videoProps.bitrate = updateBitrate(player);
    videoProps.video_progress = updateVideoProgress(player);
    if (!videoProps.hasOwnProperty('watch_time')) {
      videoProps.watch_time = null;
    }
    this.setVideo(videoProps);

    return videoProps;
  };

  function getUseragent() {
    return (window.navigator && window.navigator.userAgent) || null;
  }

  function getUserAuthenticated() {
    return !!(d3.DtUser && d3.DtUser.check() != null);
  }

  // returns the salesforce id from storage
  function getSalesforceId() {
    return (d3.utils && d3.utils.user && d3.utils.user.getSalesforceIdFromStorage()) || null;
  }

  // returns the screen resolution (width, height)
  function getScreenResolution() {
    let screenWidth = window.screen.width;
    let screenHeight = window.screen.height;

    return {
      width: screenWidth,
      height: screenHeight,
      resolution: screenWidth + ' x ' + screenHeight,
    };
  }

  function getPageProperties() {
    const props = {
      authenticated: getUserAuthenticated(),
      nation: d3.userNation,
      user_nation: d3.userNation,
      page_hostname: (window.location && window.location.hostname) || null,
      page_path: (window.location && window.location.pathname) || null,
      page_url: (window.location && window.location.href) || null,
      platform: getUseragent() || 'unknown',
      referrer: document.referrer || null,
      salesforce_id: getSalesforceId(),
      domain_source: window.deltatre.utils.paths.getPartnerDomain() || null,
    };

    try {
      if (window.deltatre.isEmbedded) {
        props['page_url'] = parent.window.location.href;
        props['page_path'] = parent.window.location.pathname;
      }
    } catch (err) {
      if (d3.diagnostic) {
        d3.diagnostic.log(`VIDEO TRACKING - ${err}`);
      }
    }

    return props;
  }

  function updateBitrate(player) {
    let instance = player.playerInstance || {};
    let activeQuality = {};
    if (instance.videoTracks && instance.videoTracks.length > 0) {
      activeQuality = instance.videoTracks[0].activeQuality;
    }

    return (activeQuality || {}).height || null;
  }

  function roundWithOneDecimal(number) {
    if (number && number > 0) {
      return Math.round(number * 10) / 10;
    }

    return 0;
  }

  function updateVideoProgress(player) {
    let instance = player.playerInstance || {};

    return roundWithOneDecimal(
      (roundWithOneDecimal(instance.currentTime) / roundWithOneDecimal(instance.duration)) * 100,
    );
  }

  function checkIfContentIsLive(content) {
    let isLive = false;

    if (content?.metaData && content?.metaData?.videoType) {
      isLive = content.metaData.videoType.toLowerCase() !== 'vod';
    }

    return isLive;
  }

  function setTrackHandler(eventName) {
    namespace.track(eventName, regwallTracking.defaultTrackingProps(true));
  }
})(window, window.deltatre || {});
