/**
 * @description Get the computed css properties for a specifict html element
 * @param HTMLElement element
 * @param string property
 * @returns Read-Only key-value object with computed properties of the element
 */
export const computedStyle = (element, property) => {
  if (!element) return undefined;

  const cssProperties = element.currentStyle || getComputedStyle(element);

  if (property) return cssProperties[property];
  else return cssProperties;
};
