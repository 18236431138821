const getElementDataset = (instanceId, element) =>
  element ? { ...element.dataset, ...element[instanceId] } : {};

const setElementDataset = (instanceId, element, key, value) => {
  if (!element || !key) return {};

  if (typeof key === 'object') element[instanceId] = { ...element[instanceId], ...key };
  else element[instanceId] = { ...element[instanceId], [key]: value };

  return getElementDataset(instanceId, element);
};

/**
 * @description Function that emulates the .data() function of jQuery as It allows to store data inside attributes using an expando (https://api.jquery.com/jQuery.data/#jQuery-data-element-key-value)
 * @param string instanceId Dom class instance id (used as key for the expando)
 * @param HTMLElement element Element where to store/fetch the data
 * @param string key Key of the data to retrieve/set. I could also be an object, in this case the value is not required.
 * @param string value Value to be set
 * @returns The data set or retrieved
 */
export const handleData = (instanceId, element, key, value) => {
  if (!element) return {};

  const dataset = getElementDataset(instanceId, element);

  if (!key) return dataset;

  if (value || typeof key === 'object') return setElementDataset(instanceId, element, key, value);
  else return dataset[key];
};
