// Matches dashed string for camelizing
const rmsPrefix = /^-ms-/;
const rdashAlpha = /-([a-z])/g;

// Used by camelCase as callback to replace()
const fcamelCase = (_all, letter) => letter.toUpperCase();

// Convert dashed to camelCase; Example camelCase('foo-bar') => fooBar
export function camelCase(value) {
  return value.replace(rmsPrefix, 'ms-').replace(rdashAlpha, fcamelCase);
}
