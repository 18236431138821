import { computedStyle } from './css';

/**
 * @name getHeight
 * @param entity HTML, Window or Document element
 * @description Get the current computed height for the first element in the set of matched elements.
 * @summary will always return the content height, regardless of the value of the CSS box-sizing property.
 */
export const getHeight = (entity, options = {}) => {
  if (entity instanceof Window) return entity.innerHeight;

  if (entity instanceof Document) return getHeightOfElement(entity.documentElement, options);

  if (entity instanceof HTMLElement) return getHeightOfElement(entity, options);

  return 0;
};

export const getWidth = (entity, options = {}) => {
  if (entity instanceof Window) return entity.innerWidth;

  if (entity instanceof Document) return getWidthOfElement(entity.documentElement, options);

  if (entity instanceof HTMLElement) return getWidthOfElement(entity, options);

  return 0;
};

const getHeightOfElement = (element, { isOuter, includeMargin }) => {
  const properties = computedStyle(element);
  const height = element.getBoundingClientRect().height;

  const marginTop = parseFloat(properties.marginTop);
  const marginBottom = parseFloat(properties.marginBottom);
  const borderWidth = parseFloat(properties.borderWidth);
  const paddingTop = parseFloat(properties.paddingTop);
  const paddingBottom = parseFloat(properties.paddingBottom);

  // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect#return_value
  if (isOuter) return height + (includeMargin ? marginTop + marginBottom : 0);
  else return height - borderWidth - paddingTop - paddingBottom;
};

const getWidthOfElement = (element, { isOuter, includeMargin }) => {
  const properties = computedStyle(element);
  const height = element.getBoundingClientRect().height;

  const marginLeft = parseFloat(properties.marginLeft);
  const marginRight = parseFloat(properties.marginRight);
  const borderWidth = parseFloat(properties.borderWidth);
  const paddingLeft = parseFloat(properties.paddingLeft);
  const paddingRight = parseFloat(properties.paddingRight);

  // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect#return_value
  if (isOuter) return height + (includeMargin ? marginLeft + marginRight : 0);
  else return height - borderWidth - paddingLeft - paddingRight;
};
