import dom from '../../scripts/components/utils/dom/index';

/*
 * Handles Playlist CountDown of THEOplayer
 */
(function (window, d3) {
  'use strict';

  // define config
  let config = d3 && d3.config,
    utils = d3 && d3.utils;

  // check dependencies
  if (!config) {
    throw new Error('OCSvp:: videoCountdown.config missing');
  }

  if (!utils) {
    throw new Error('OCSvp:: videoCountdown.utils missing');
  }

  if (!(d3.ocsvp && d3.ocsvp.tracking)) {
    throw new Error('OCSvp::tracking missing');
  }

  // tracking
  let tracking = d3.ocsvp.tracking;

  // namespace
  d3.ocsvp = d3.ocsvp || {};
  d3.ocsvp.countdown = d3.ocsvp.countdown || {};
  let namespace = d3.ocsvp.countdown,
    dataPlaylistEntityIdSeed = '*[data-playlist-entityId=',
    countdownIsOn = false,
    classNameOverlaySeed = '.js-countdown-overlay-',
    classNameOverlay = '',
    // classNameOverlayPlaylist,
    initialEntityId,
    currentIndex,
    currentDurationCountdown,
    durationCountDown = 5,
    display,
    backgroundImage,
    // fullScreenMode = 'fullscreen',
    // pictureInPictureMode = 'picture-in-picture',
    inLineMode = 'inline',
    countDownPlayNextItemClass = ' .js-countdown-up-next-playing',
    isRtl = false,
    myPlayer;

  namespace.setContent = function (props, player) {
    if (props.length === 0) {
      return;
    }

    //Set variables
    myPlayer = player;

    if (utils.code.checkIsNil(myPlayer.currentContent)) {
      return;
    }

    if (utils.code.checkIsNil(initialEntityId)) {
      if (
        myPlayer.options &&
        myPlayer.options.isOriginalSerie &&
        myPlayer.options.isOriginalSerie.toString().toLowerCase() === 'true'
      ) {
        initialEntityId = myPlayer.currentContent.entityId;
      } else {
        initialEntityId = myPlayer.playlist[0].entityId;
      }
    }

    classNameOverlay = classNameOverlaySeed + initialEntityId;

    if (utils.code.getLanguageFromHtml().toUpperCase().indexOf('AR') > -1) {
      isRtl = true;
    }

    currentIndex = myPlayer.playlistIndex;

    // If is the last video, we take the poster of the first one
    if (currentIndex + 1 === myPlayer.playlist.length) {
      backgroundImage = myPlayer.playlist[0].placeholder;
    } else {
      backgroundImage = myPlayer.playlist[currentIndex + 1].placeholder;
    }

    display = dom(classNameOverlay + countDownPlayNextItemClass);
  };

  //Public Methods
  namespace.setPlayButton = function () {
    //dom(classNameOverlay + ' .icon-play').click(function () {
    dom('.icon-play')
      .parent()
      .click(function () {
        resetCountdown(currentDurationCountdown, display);
      });
  };

  namespace.setPlayPauseButton = function () {
    dom(classNameOverlay + ' .js-countdown-btn-stop').click(function stopCountDown(event) {
      event.preventDefault();

      let buttonText = dom(this).text(),
        countDownDataPlay = dom(this).data('countdownPlay'),
        countDownDataStop = dom(this).data('countdownStop');

      if (buttonText === countDownDataStop) {
        //Stop timer
        //console.log("Stop timer");

        clearInterval(window.plcountdown);

        dom('.countdown--overlay .countdown--icon.active circle').css(
          'animation-play-state',
          'paused',
        );

        dom(this).text(countDownDataPlay);
      } else {
        //Start timer
        //console.log("Start timer");

        startTimer(currentDurationCountdown - 1, display);

        dom(this).text(countDownDataStop);
      }
    });
  };

  namespace.setCountDown = function () {
    if (myPlayer.playlist.length < 2) return;

    if (dom(classNameOverlay).length > 0) {
      //Fullscreen + picture-in-picture = play next
      if (myPlayer.playerInstance.presentation.currentMode != inLineMode) {
        playNextItem();

        return;
      }

      $(classNameOverlay).prepend($(classNameOverlay));
      $('.b2p-player__wrapper').before($(classNameOverlay));

      //Adds background image + overlay gradient
      dom(classNameOverlay).css(
        'background',
        'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + backgroundImage + ')',
      );
      dom(classNameOverlay).css('background-size', 'cover');

      //Removes the playlist's right white gradient
      //dom(dom(classNameOverlay)[0].children[1].children[1]).addClass("playlist--episodes-alternative");

      setTitleCountdown();

      dom(classNameOverlay).show();

      //Wrapper
      setPlaylistType();

      if (window.deltatre.isEmbedded) {
        addClickHandlerSyndicatedPlaylistCountdownOverlay();
      }

      dom('.countdown--icon:not(.active)').addClass('active');

      if (dom(classNameOverlay + countDownPlayNextItemClass).is('[data-countdown-sec]')) {
        durationCountDown =
          $(classNameOverlay + countDownPlayNextItemClass).data('countdown-sec') - 1;
      }

      countdownIsOn = true;

      myPlayer.playerInstance.stop();

      startTimer(durationCountDown, display);
    }
  };

  function setPlaylistType() {
    window.deltatre.playLists.setPlaylistType();
  }

  function startTimer(duration, display) {
    dom('.countdown--overlay .countdown--icon.active circle').css(
      'animation-play-state',
      'running',
    );

    if (!utils.code.checkIsNil(window.plcountdown)) {
      clearInterval(window.plcountdown);
    }

    window.plcountdown = setInterval(function () {
      currentDurationCountdown = duration;
      display.text(getPlayingTranslation(duration));

      if (--duration < 0) {
        resetCountdown(duration, display);
      } else if (!countdownIsOn) {
        clearInterval(window.plcountdown);
      }
    }, 1000);
  }

  function setTitleCountdown() {
    let nextIndex = myPlayer.playlistIndex + 1,
      title = '',
      playListLength = myPlayer.playlist.length;

    //if it is the (last - 1) playlist item, set the next title countdown to first item (index == 0)
    if (nextIndex === playListLength) {
      nextIndex = 0;
    } else if (nextIndex > playListLength) {
      //if it is the last playlist item, set the next title countdown to second item (index == 1)
      nextIndex = 1;
    }

    title = myPlayer.playlist[nextIndex].title;

    let classNameOverlayNextTitleClass = classNameOverlay + ' .js-countdown-up-next-title';

    if (!window.deltatre.utils.code.checkIsNil(title)) {
      dom(classNameOverlayNextTitleClass).text(title);
    }
  }

  function updateInfoCurrentVideo(nextIndex, prevIndex) {
    let playlist = myPlayer.playlist;

    if (!playlist || playlist.length === 0) return;

    let $divVideoPlayerPlaylist = dom('*[data-playlist-entityId="' + initialEntityId + '"]');

    //Get Article Body
    let $divArticle = $divVideoPlayerPlaylist.parent().parent().find('div.row.article--body');

    //Get next item's title and description
    let title = playlist[nextIndex].title || '';
    let description = playlist[nextIndex].description || '';

    let hidingElements = document.querySelectorAll('[data-video-hide-on-next]');
    if (hidingElements.length > 0) {
      hidingElements.forEach(function (element) {
        if (!element.classList.contains('d-none')) {
          element.classList.add('d-none');
        }
      });
    }

    let descriptionExpander = document.querySelectorAll('[data-desc-expander]');
    if (descriptionExpander.length > 0) {
      descriptionExpander.forEach(function (element) {
        if (!element.classList.contains('-expanded')) {
          element.classList.add('-expanded');
        }
      });
    }

    //check bug-overlay
    let videoContainer = document.querySelector('[data-bug-overlay-tag]');
    if (videoContainer) {
      let overlayTag = videoContainer.dataset.bugOverlayTag;
      let isTagged = playlist[nextIndex].tags?.findIndex((f) => f.Slug === overlayTag) >= 0;
      if (isTagged && !videoContainer.classList.contains('-bug-overlay')) {
        //Add the overlay
        videoContainer.classList.add('-bug-overlay');
      } else if (!isTagged && videoContainer.classList.contains('-bug-overlay')) {
        //Remove the overlay
        videoContainer.classList.remove('-bug-overlay');
      }
    }

    //Updates Title
    setArticleTexts(title, $divArticle, window.deltatre.playLists.setArticleTitle);

    //Updates Description
    setArticleTexts(description, $divArticle, window.deltatre.playLists.setArticleDescription);

    //Updates Tags
    setArticleData(
      $divArticle,
      nextIndex,
      '#video-tags',
      window.deltatre.playLists.setArticleTags,
      updateVideoTags,
    );

    //Updates Roofline
    setArticleData(
      $divArticle,
      nextIndex,
      '#roofline-container',
      setArticleRoofline,
      updateVideoRoofline,
    );

    setNewPageUrl(nextIndex);

    setMetaAndSocialInfo(nextIndex, playlist);

    //Updates current("Now Playing") classes
    setPlayListCurrentClasses(nextIndex, prevIndex);

    //Uncomment the following lines if you like to manage setPlayListCurrentClasses() from here instead of DtPlaylistCarousel.jsx and comment the above line
    //var indexesData = {
    //    prevIndex: prevIndex,
    //    nextIndex: nextIndex
    //}

    //document.dispatchEvent(myEvent);
    //End Uncomment
  }

  function setArticleRoofline(index, playList) {
    let rooflineContainer = dom('#roofline-container');
    let primaryClass = 'roofline-kind';
    let secondaryClass = 'roofline-info';
    if (rooflineContainer.length > 0) {
      let primaryCustomClass = rooflineContainer.data('primaryclass');
      if (primaryCustomClass !== null) {
        primaryClass = primaryCustomClass;
      }
      let secondaryCustomClass = rooflineContainer.data('secondaryclass');
      if (secondaryCustomClass !== null) {
        secondaryClass = secondaryCustomClass;
      }
    }

    window.deltatre.playLists.setArticleRoofline(
      index,
      playList,
      true,
      false,
      false,
      primaryClass,
      secondaryClass,
    );
  }

  function setArticleTexts(text, divArticle, callBack) {
    callBack(text, divArticle);
  }

  function setArticleData(divArticle, nextIndex, idString, ifTrueCallback, ifFalseCallback) {
    let container = dom(idString);

    if (!window.deltatre.utils.code.checkIsNil(container) && container.length > 0) {
      ifTrueCallback(nextIndex, myPlayer.playlist);

      return;
    }

    ifFalseCallback(divArticle, nextIndex);
  }

  function setMetaAndSocialInfo(nextIndex, playList) {
    let articleTitle = dom(window.deltatre.playLists.articleBodyTitleId);

    if (!window.deltatre.utils.code.checkIsNil(articleTitle) && articleTitle.length > 0) {
      window.deltatre.playLists.setArticleMetaAndSocialInfo(nextIndex, playList);

      return;
    }

    updateMetaAndSocialInfo(nextIndex);
  }

  function setPlayListCurrentClasses(nextIndex, prevIndex) {
    window.deltatre.playLists.setPlayListCurrentClasses(nextIndex, prevIndex);
  }

  function updateMetaAndSocialInfo(nextIndex) {
    let playlistItemObj = myPlayer.playlist[nextIndex];

    if (utils.code.checkIsNil(playlistItemObj)) {
      return;
    }

    //Page
    getAndSetNewTitleTag(playlistItemObj.title);
    window.deltatre.utils.dom.setMetaTagValue('description', playlistItemObj.description);
    dom('link[rel=canonical]').attr('href', window.location.href);

    //Generic
    window.deltatre.utils.dom.setMetaTagValue('VODEntityId', playlistItemObj.entityId);
    getAndSetNewMetaTag(playlistItemObj, 'DisciplineName', 'discipline', true);
    getAndSetNewMetaTag(playlistItemObj, 'DisciplineSlug', 'discipline');
    getAndSetNewMetaTag(playlistItemObj, 'AthleteSlug', 'athlete');
    getAndSetNewMetaTag(playlistItemObj, 'AthleteName', 'athlete');

    // Opengraph
    window.deltatre.utils.dom.setMetaTagValue('og\\:url', window.location.href);
    window.deltatre.utils.dom.setMetaTagValue('og\\:title', playlistItemObj.title);
    window.deltatre.utils.dom.setMetaTagValue('og\\:description', playlistItemObj.description);
    window.deltatre.utils.dom.setMetaTagValue('og\\:image', playlistItemObj.placeholder);
    window.deltatre.utils.dom.setMetaTagValue(
      'og\\:image\\:secure_url',
      playlistItemObj.placeholder,
    );

    //meta
    window.deltatre.utils.dom.setMetaTagValue('video_url', playlistItemObj.src);
    window.deltatre.utils.dom.setMetaTagValue('show_synopsis', playlistItemObj.description);
    window.deltatre.utils.dom.setMetaTagValue('episode_synopsis', playlistItemObj.description);
    window.deltatre.utils.dom.setMetaTagValue('episode_title', playlistItemObj.title);
    setNewTags(playlistItemObj);
    window.deltatre.utils.dom.setMetaTagValue('episode_uid', playlistItemObj.entityId);
  }

  function setNewPageUrl(nextIndex) {
    let $divVideoPlayerPlaylist = dom('*[data-playlist-entityId="' + initialEntityId + '"]');
    //Video detail view
    let url = $divVideoPlayerPlaylist
      .find('[data-episode-index="' + nextIndex + '"] a')
      .attr('href');

    //Originals video player
    if (!url) {
      url = $divVideoPlayerPlaylist
        .find('[data-episode-index="' + nextIndex + '"]')
        .parent()
        .attr('href');
    }

    window.history.replaceState('', '', url);
  }

  function setNewTags(playlistItemObj) {
    window.deltatre.playLists.setNewTags(playlistItemObj);
  }

  function getAndSetNewMetaTag(playlistItemObj, metaTagName, externalSourceName, useTitle) {
    useTitle = window.deltatre.utils.code.checkAndSetDefaultValue(useTitle, false);

    if (utils.code.checkIsNil(playlistItemObj)) {
      return;
    }

    let tagsArray = playlistItemObj.metaData.tags,
      x,
      tagsLength = tagsArray.length;

    if (tagsLength > 0) {
      for (x = 0; x < tagsLength; x = x + 1) {
        if (
          !utils.code.checkIsNil(tagsArray[x].externalSourceName) &&
          tagsArray[x].externalSourceName.indexOf(externalSourceName) > -1
        ) {
          let value = tagsArray[x].slug;

          if (useTitle) {
            value = tagsArray[x].title;
          }

          window.deltatre.utils.dom.setMetaTagValue(metaTagName, value);

          return;
        }
      }
    } else {
      window.deltatre.utils.dom.setMetaTagValue(metaTagName, '');
    }
  }

  function getAndSetNewTitleTag(newTitle) {
    window.deltatre.playLists.getAndSetNewTitleTag(newTitle);
  }

  function updateVideoRoofline(jqueryRefDivArticle, nextIndex) {
    let playList = myPlayer.playlist;

    if (window.deltatre.utils.code.checkIsNil(playList[nextIndex].metaData.roofline)) return;

    let rooflinePrimary = playList[nextIndex].metaData.roofline.primary || '',
      rooflineLinkPrimary = playList[nextIndex].metaData.roofline.linkPrimary || '',
      rooflineSecondary = playList[nextIndex].metaData.roofline.secondary || '',
      rooflineLinkSecondary = playList[nextIndex].metaData.roofline.linkSecondary || '';

    let $divArticleInfo = jqueryRefDivArticle.find('.article--info').first();
    $divArticleInfo.empty();

    if (rooflineLinkPrimary && rooflinePrimary) {
      $divArticleInfo.append(
        '<a href="' + rooflineLinkPrimary + '" class="article--kind">' + rooflinePrimary + '</a>',
      );
    }

    if (rooflineLinkSecondary && rooflineSecondary) {
      $divArticleInfo.append(
        '<a href="' +
          rooflineLinkSecondary +
          '" class="article--kind__info">' +
          rooflineSecondary +
          '</a>',
      );
    }
  }

  function updateVideoTags(jqueryRefDivArticle, nextIndex) {
    let playList = myPlayer.playlist;
    let $ulTags = jqueryRefDivArticle.find('.list-unstyled.tag-list');
    $ulTags.empty();

    if (!playList[nextIndex].metaData || !playList[nextIndex].metaData.tags) return;

    let i,
      playlistLength = playList[nextIndex].metaData.tags.length,
      showTagList = false;

    for (i = 0; i < playlistLength; i = i + 1) {
      let selfUrl = playList[nextIndex].metaData.tags[i].selfUrl || '',
        title = playList[nextIndex].metaData.tags[i].title || '';

      if (selfUrl && title) {
        $ulTags.append(
          '<li class="list-item"><a href="' +
            selfUrl +
            '"><span class="tab">' +
            title +
            '</span></a></li>',
        );

        showTagList = true;
      }
    }

    let $divArticleTags = jqueryRefDivArticle.find('.article--tags');

    if (!showTagList && !$divArticleTags.hasClass('invisible')) {
      $divArticleTags.addClass('invisible');
    } else if (showTagList && $divArticleTags.hasClass('invisible')) {
      $divArticleTags.removeClass('invisible');
    }
  }

  function getPlayingTranslation(durationCountDown, isDefaultTranslation) {
    let playingTranslation = '';

    if (dom(classNameOverlay + countDownPlayNextItemClass).is('[data-countdown-translation]')) {
      playingTranslation = $(classNameOverlay + countDownPlayNextItemClass).data(
        'countdown-translation',
      );
    }

    if (isDefaultTranslation) {
      if (dom(classNameOverlay + countDownPlayNextItemClass).is('[data-countdown-sec]')) {
        durationCountDown = $(classNameOverlay + countDownPlayNextItemClass).data('countdown-sec');
      }
    }

    return playingTranslation.replace('{0}', durationCountDown);
  }

  function addClickHandlerSyndicatedPlaylistCountdownOverlay() {
    let $olswiper = dom(classNameOverlay).find('#playlist-swiper');
    if ($olswiper.length > 0) {
      $olswiper.attr('id', 'playlist-ol-swiper');

      if ($olswiper.length > 0) {
        $olswiper.find('a').on('click', function (e) {
          e.preventDefault();

          if (window.plcountdown) {
            clearInterval(window.plcountdown);
          }

          let $link = dom(e.target);

          if (!$link.is('a')) {
            if (!$link.closest('a').hasClass('cursor-default')) {
              $link = $link.closest('a');
            } else {
              return;
            }
          }

          dom(classNameOverlay).hide();

          let $ep = $link.find('[data-episode-index]');

          if ($ep.length > 0) {
            let idx = $ep.first().attr('data-episode-index');

            if (idx) {
              resetCountdown(currentDurationCountdown, display, idx);
            }
          }
        });
      }
    }
  }

  function playNextItem() {
    resetCountdown(0, display);
  }

  function resetCountdown(duration, display, optionalIndex) {
    dom(classNameOverlay).hide();

    dom(classNameOverlay + ' .js-countdown-btn-stop').text(
      dom(classNameOverlay + ' .js-countdown-btn-stop').data('countdown-stop'),
    );

    //remove the playlist in the countdown overlay, because will be added an updated version
    //dom(classNameOverlayPlaylist).remove();

    let nextIndex = getNextIndex();

    if (optionalIndex) {
      nextIndex = optionalIndex;
    }

    // Set prev and next index storage
    window.deltatre.playLists.setPlaylistStorageIndex(
      window.deltatre.playLists.prevIndexStorageKey,
      currentIndex,
    );
    window.deltatre.playLists.setPlaylistStorageIndex(
      window.deltatre.playLists.nextIndexStorageKey,
      nextIndex,
    );

    if (
      !window.deltatre.utils.code.checkIsNil(window.deltatre.scrollableList?.containerId) &&
      dom(window.deltatre.scrollableList.containerId).length > 0
    ) {
      window.deltatre.scrollableList.init(nextIndex);
      myPlayer.gotoTrack(nextIndex, function () {
        updateInfoCurrentVideo(nextIndex, myPlayer.playlistIndex);
      });
    } else {
      if (window.deltatre.isEmbedded) {
        let currentPlaylistIndex = myPlayer.playlistIndex;
        myPlayer.gotoTrack(nextIndex, function () {
          setPlayListCurrentClasses(nextIndex, currentPlaylistIndex);
          myPlayer.play();
        });
      } else {
        myPlayer.gotoTrack(nextIndex, function () {
          // Update the current video in the playlist react component Carousels/b2p/PlaylistCarousel
          const evt = new CustomEvent('playlist-update-current-video', {
            detail: { index: nextIndex },
          });
          document.dispatchEvent(evt);

          updateInfoCurrentVideo(nextIndex, myPlayer.playlistIndex);

          myPlayer.play();
        });
      }

      //Moves slider to nextIndex
      window.deltatre.playLists.moveToNextIndex(nextIndex, myPlayer.playlist.length);
    }

    tracking.setConviva(myPlayer, myPlayer.currentContent);

    //check bug-overlay
    let videoContainer = document.querySelector('[data-bug-overlay-tag]');
    if (videoContainer) {
      let overlayTag = videoContainer.dataset.bugOverlayTag;
      let tags = JSON.parse(myPlayer.currentContent.analyticsData.tag);
      let isTagged = tags.findIndex((f) => f.slug === overlayTag) >= 0;
      if (isTagged && !videoContainer.classList.contains('-bug-overlay')) {
        //Add the overlay
        videoContainer.classList.add('-bug-overlay');
      } else if (!isTagged && videoContainer.classList.contains('-bug-overlay')) {
        //Remove the overlay
        videoContainer.classList.remove('-bug-overlay');
      }
    }

    let playingTranslation = getPlayingTranslation(duration, true);

    display.text(playingTranslation);

    setTitleCountdown();

    clearInterval(window.plcountdown);
  }

  function getNextIndex() {
    let playlistLength = myPlayer.playlist.length,
      nextIndex = currentIndex + 1;

    if (nextIndex > playlistLength - 1) {
      nextIndex = 0;
    }

    return nextIndex;
  }
})(window, window.deltatre || {});
