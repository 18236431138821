export function off(events, ...params) {
    let handler = null;
    let selector = null;
  
    if (params.length === 1 && typeof params[0] === 'function') handler = params[0];
  
    if (params.length === 2) {
      selector = params[0];
      handler = params[1];
    }
  
    if (!handler) {
      console.warn('D3 DOM: Unsupported Parameters');
  
      return;
    }
  
    events.split(' ').forEach((event) => {
      this.elementList.forEach((element) => {
        const requestedElements = selector ? [...element.querySelectorAll(selector)] : [element];
  
        requestedElements.forEach((requestedElement) =>
          requestedElement.removeEventListener(event, (e) => {
            handler(e);
          }),
        );
      });
    });
  }
  