import { handleData } from './dataset';
import { extendObj } from './extend';
import { isObject } from './isObject';
import { getHeight, getWidth } from './layout';
import { computedStyle } from './css';
import { ajaxDefaultSettings } from './ajaxDefaultSettings';
import { ajax } from './ajax';
import { show } from './show';
import { hide } from './hide';
import { eventMap } from './eventMap';
import { on } from './on';
import { off } from './off';
import { slideUp } from './slideUp';
import { slideDown } from './slideDown';
import { camelCase } from './camelCase';
import { eventManager } from './eventManager';
import { getParents } from './parents';

class Dom {
  static now = () => new Date().getTime();
  static instanceId = 'D3Dom_' + Dom.now();
  static ajaxDefaultSettings = ajaxDefaultSettings;

  constructor(elements) {
    this.element = elements[0];
    this.elementList = elements;
    this.value = elements[0]?.value || '';
    this.innerText = elements[0]?.innerText?.trim() || '';
  }

  static is = (element, selector) =>
    (
      element.matches ||
      element.matchesSelector ||
      element.msMatchesSelector ||
      element.mozMatchesSelector ||
      element.webkitMatchesSelector ||
      element.oMatchesSelector
    ).call(element, selector);

  //#region STATIC FUNCTIONS
  static get = (selector) => {
    if (selector instanceof Dom) return new Dom(selector.elementList);

    if (Array.isArray(selector)) return new Dom(selector);

    if (
      selector instanceof Document ||
      selector instanceof Window ||
      selector instanceof HTMLElement
    )
      return new Dom([selector]);

    return new Dom([...document.querySelectorAll(selector)]);
  };

  static data = (...params) => handleData(Dom.instanceId, ...params);
  static map = (entity, callback) => {
    if (Array.isArray(entity)) return entity.map(callback);

    return Object.entries(entity).map(([key, value]) => callback(value, key));
  };
  static extend = extendObj;
  static ajax = (...params) => {
    const options = (typeof params[0] === 'string' ? params[1] : params[0]) || {};
    const url = typeof params[0] === 'string' ? params[0] : options?.url;

    return ajax(url, { ...Dom.ajaxDefaultSettings, ...options });
  };
  static ajaxSetup = (options = {}) =>
    (Dom.ajaxDefaultSettings = { ...Dom.ajaxDefaultSettings, ...options });

  static camelCase = camelCase;

  static trim = (data) => {
    return data?.trim();
  };
  //#endregion

  //#region GETTERS
  /**
   * @description Get the number of children nodes (based on https://api.jquery.com/length)
   */
  get length() {
    return this.elementList?.length || 0;
  }
  //#endregion

  [Symbol.iterator] = function* () {
    for (const item of this.elementList) {
      yield item;
    }
  };

  prop = (name) => this.element[name];

  at = (index) => this.elementList[index];

  /**
   * @description Show the first selected element with css-based fade-in animation (based on https://api.jquery.com/show)
   */
  show = (...params) => show.call(this, ...params);

  /**
   * @description Hide the first selected element with css-based fade-out animation (based on https://api.jquery.com/hide)
   */
  hide = (...params) => hide.call(this, ...params);

  toggle = (callback) => {
    // Handle https://api.jquery.com/toggle/#toggle-display
    if (typeof callback === 'boolean') {
      return callback ? this.show() : this.hide();
    }

    if (this.element.style.display === 'none') {
      this.show();
    } else {
      this.hide();
    }

    if (typeof callback === 'function') {
      return callback();
    }
  };

  /**
   * @description Trigger click event or add a click event listener (based on https://api.jquery.com/click)
   */
  click = (...params) => {
    if (!params.length) {
      this.trigger('click');

      return;
    }

    if (params.length === 1 && typeof params[0] === 'function') {
      this.element?.addEventListener('click', params[0]);

      return;
    }

    const eventData = params[0];
    const callback = params[1];

    this.element?.addEventListener('click', (ev) => callback(ev, eventData));
  };

  /**
   * @description Trigger an event based on type (based on https://api.jquery.com/trigger)
   * @param {*} ev Event Type (string) or Event Object
   */
  trigger = (ev, params) => {
    let eventType = ev;
    let eventParams = params;
    if (ev instanceof Event) {
      this.element?.dispatchEvent(ev);

      return this;
    }

    if (typeof ev.type === 'string') {
      const { type, ...paramObject } = ev;

      eventType = type;
      eventParams = { detail: paramObject };
    }

    const EventClass = eventMap[eventType] || CustomEvent;

    const event = new EventClass(eventType, eventParams);

    this.element?.dispatchEvent(event);

    return this;
  };

  /**
   * @description Attach an event handler function for one or more events to the selected elements (based on https://api.jquery.com/on)
   */
  on = (...params) => on.call(this, ...params);

  /**
   * @description  Remove an event handler (based on https://api.jquery.com/off)
   */
  off = (...params) => off.call(this, ...params);

  /**
   * @description Get or Set the value of the first selected element (based on https://api.jquery.com/val)
   */
  val = (value) => {
    if (value && this.element) this.element.value = value;

    if (this.element.tagName === 'SELECT' && this.attr('multiple') !== undefined) {
      return [...this.element.options].filter((item) => item.selected).map((item) => item.value);
    }

    return this.element?.value;
  };

  /**
   * @description Check if selector match the element (based on https://api.jquery.com/is/)
   */
  is = (selector) => Dom.is(this.element, selector);

  /**
   * @description Get or Set an attribute of the first selected element (based on https://api.jquery.com/attr)
   */
  attr = (key, value) => {
    if (!key) return this.element?.attributes;

    if (!value && value !== null) return this.element?.attributes[key]?.value;

    if (this.element && value) {
      this.element.setAttribute(key, value);

      return value;
    }

    if (this.element && value === null) {
      this.element.removeAttribute(key, value);

      return value;
    }

    return this.element?.attributes[key]?.value || undefined;
  };

  /**
   * @description Get or Set the innerText of the selected elements (based on https://api.jquery.com/text)
   */
  text = (value) => {
    if (value) this.elementList.forEach((element) => (element.innerText = value));

    return this.elementList.reduce((acc, element) => `${acc} ${element.innerText}`.trim(), '');
  };

  /**
   * @description Get or Set the innerHTML of the selected elements (based on https://api.jquery.com/html)
   */
  html = (value) => {
    if (typeof value === 'string')
      this.elementList.forEach((element) => (element.innerHTML = value));

    return this.elementList.reduce((acc, element) => `${acc} ${element.innerHTML}`.trim(), '');
  };

  /**
   * @description Get the first element in the set of matched elements.
   */
  first = () => Dom.get(this.elementList[0]);

  last = () => Dom.get(this.elementList[this.elementList.length - 1]);

  /**
   * @description Get the current computed height for the first element in the set of matched elements (based on https://api.jquery.com/height).
   * @summary Will always return the content height, regardless of the value of the CSS box-sizing property.
   */
  height = () => getHeight(this.element);

  /**
   * @description Get the current computed width for the first element in the set of matched elements (based on https://api.jquery.com/width).
   * @summary Will always return the content width, regardless of the value of the CSS box-sizing property.
   */
  width = () => getWidth(this.element);

  /**
   * @description Get the current computed outer height (including padding, border, and optionally margin) for the first element in the set of matched elements (based on https://api.jquery.com/outerHeight).
   */
  outerHeight = (includeMargin = false) =>
    getHeight(this.element, { isOuter: true, includeMargin });

  /**
   * @description Get the current computed outer width (including padding, border, and optionally margin) for the first element in the set of matched elements (based on https://api.jquery.com/outerHeight).
   */
  outerWidth = (includeMargin = false) => getWidth(this.element, { isOuter: true, includeMargin });

  /**
   * @description Get the first element that matches the selector by testing the element itself and traversing up through its ancestors in the DOM tree (based on https://api.jquery.com/closest).
   */
  closest = (selectors) => Dom.get(this.element?.closest(selectors));

  /**
   * @description Get elements that matches the selector by testing the element itself and traversing up through its ancestors in the DOM tree (based on https://api.jquery.com/find).
   */
  find = (selector) =>
    Dom.get([
      ...(this.elementList?.reduce(
        (acc, element) => [...acc, ...element?.querySelectorAll(selector)],
        [],
      ) || []),
    ]);

  /**
   * @description Pass each element in the current matched set through a function. (based on https://api.jquery.com/map).
   */
  map = (callback) => this.elementList.map((element) => callback.call(element, element));

  /**
   * @description Detect change on the selector (based on https://api.jquery.com/change/).
   */
  change = (...params) => eventManager.call(this, 'change', ...params);

  /**
   * @description Replace element content with new content (based on https://api.jquery.com/replacewith/).
   */
  replaceWith = (content) => {
    this.element.innerHTML = content;
  };

  /**
   * @description Set content after selector (based on https://api.jquery.com/after/).
   */
  after = (content) => {
    this.elementList.forEach((element) => {
      element.innerHTML += content;
    });
  };

  /**
   * @description Store arbitrary data associated with the matched elements. (based on https://api.jquery.com/data).
   */
  data = (key, value) => {
    if (value) {
      this.elementList.forEach((element) => Dom.data(element, key, value));

      return undefined;
    }

    return Dom.data(this.element, key, value);
  };

  /**
   * @description Specify a function to execute when the DOM is fully loaded (based on https://api.jquery.com/ready).
   */
  ready = (callback) => {
    if (!(this.element instanceof Document)) {
      console.error('D3 DOM ERROR: Selected element must be a document element');

      return this;
    }

    const isDomAlreadyLoaded =
      this.element.readyState === 'complete' ||
      this.element.readyState === 'loaded' ||
      this.element.readyState === 'interactive';

    if (isDomAlreadyLoaded) callback();
    else this.element.addEventListener('DOMContentLoaded', callback);

    return this;
  };

  /**
   * @description Get the value of a computed style property for the first element in the set of matched elements or set one or more CSS properties for every matched element. (based on https://api.jquery.com/css).
   */
  css = (property, value) => {
    if (value && this.element)
      this.elementList.forEach((element) => (element.style[property] = value));

    return computedStyle(this.element, property);
  };

  /**
   * @description Determine whether any of the matched elements are assigned the given class. (based on https://api.jquery.com/hasClass).
   */
  hasClass = (className) =>
    !!this.elementList.find((element) => element.classList.contains(className?.trim()));

  /**
   * @description Adds the specified class(es) to each element in the set of matched elements. (based on https://api.jquery.com/addClass).
   */
  addClass = (classNames) => {
    classNames
      .split(' ')
      .forEach((className) =>
        this.elementList?.forEach((element) => element.classList.add(className)),
      );

    return this;
  };

  /**
   * @description Remove a single class or multiple classes from each element in the set of matched elements. (based on https://api.jquery.com/removeClass).
   */
  removeClass = (classNames) => {
    classNames
      .split(' ')
      .forEach((className) =>
        this.elementList?.forEach((element) => element.classList.remove(className)),
      );

    return this;
  };

  remove = () => {
    return this.element?.remove();
  };

  /**
   * @description Get the current coordinates of the first element, or set the coordinates of every element, in the set of matched elements, relative to the document. (based on https://api.jquery.com/offset/#offset)
   */

  offset = () => {
    return this.element?.getBoundingClientRect();
  };

  /**
   * @description Bind an event handler to the "blur" JavaScript event, or trigger that event on an element. (based on https://api.jquery.com/blur)
   */

  blur = (...params) => eventManager.call(this, 'blur', ...params);

  /**
   * @description Bind an event handler to the "focusout" JavaScript event. (based on https://api.jquery.com/focusout)
   */
  focusout = (...params) => eventManager.call(this, 'focusout', ...params);

  /**
   * @description  Remove the set of matched elements from the DOM. (based on https://api.jquery.com/remove)
   */

  remove = (selector) => {
    if (this.element === undefined) return {};

    if (selector !== undefined) {
      let selectedElements = this.element.querySelectorAll(selector);

      selectedElements.forEach((selectedElement) => selectedElement.remove());

      return;
    }

    this.elementList.forEach((node) => {
      while (node.hasChildNodes()) {
        node.removeChild(node.firstChild);
      }
      node.remove();
    });
  };

  toggleClass = (classNames, state) => {
    classNames.split(' ').forEach((className) => {
      this.elementList?.forEach((element) =>
        (state === undefined && element.classList.contains(className)) ||
        (state !== undefined && !state)
          ? element.classList.remove(className)
          : element.classList.add(className),
      );
    });

    return this;
  };

  append = (...params) => {
    const handler = typeof params[0] === 'function' ? params[0] : null;
    const parser = new DOMParser();

    if (handler)
      this.elementList.forEach(
        (element, index) =>
          (element.innerHTML = element.innerHTML + handler.call(element, index, element)),
      );
    else
      this.elementList.forEach((element) =>
        params.forEach((content) => {
          if (content instanceof HTMLElement) element.append(content);

          if (content instanceof Dom)
            content.elementList.forEach((domElement) => element.append(domElement));

          if (typeof content === 'string') {
            const parsedContent = parser.parseFromString(content, 'text/html');
            element.append(...parsedContent.body.children);
          }
        }),
      );
  };

  appendTo = (...params) => {
    const handler = typeof params[0] === 'function' ? params[0] : null;

    if (handler) {
      this.elementList.forEach(
        (element, index) =>
          (element.innerHTML = element.innerHTML + handler.call(element, index, element)),
      );
    } else {
      params.forEach((content) => {
        if (content instanceof Dom) {
          // HTML element native append
          content.element.append(this.element);
        }
      });
    }
  };

  clone = () => {
    return this.element.cloneNode(true);
  };

  prepend = (...params) => {
    const handler = typeof params[0] === 'function' ? params[0] : null;

    if (handler)
      this.elementList.forEach(
        (element, index) =>
          (element.innerHTML = element.innerHTML + handler.call(element, index, element)),
      );
    else
      this.elementList.forEach((element) =>
        params.forEach((content) => {
          let contentToPrepend = content;
          if (content instanceof HTMLElement) contentToPrepend = content.outerHTML;

          if (content instanceof Dom)
            contentToPrepend = content.elementList.reduce(
              (acc, curr) => `${acc}${curr.outerHTML}`,
              '',
            );

          element.innerHTML = contentToPrepend + element.innerHTML;
        }),
      );
  };

  before = (elem) => {
    return this.element?.insertAdjacentHTML('beforebegin', elem);
  };

  empty = () => {
    return this.html('');
  };

  children = (selector, all) => {
    const matchedElements = this.elementList.reduce(
      (acc, element) => [
        ...acc,
        ...(selector
          ? element.querySelectorAll(all ? selector : `:scope > ${selector}`)
          : element.children),
      ],
      [],
    );

    return Dom.get(matchedElements || []);
  };

  parent = (selector) => {
    const matchedElements = this.elementList.reduce(
      (acc, element) => [
        ...acc,
        ...(selector ? [...element.parentElement.matches(selector)] : [element.parentElement]),
      ],
      [],
    );

    return Dom.get(matchedElements || []);
  };

  parents = (selector) => Dom.get(getParents.call(this, selector));

  slideUp = (...params) => slideUp.call(this, ...params);

  slideDown = (...params) => slideDown.call(this, ...params);

  not = (selectors) => Dom.get(this.elementList.filter((element) => !element.matches(selectors)));

  siblings = (selector) => {
    const childNodes = [...this.element.parentElement.children];

    const siblings = childNodes.filter(
      (x) => ((selector && Dom.is(x, selector)) || !selector) && x !== this.element,
    );

    return Dom.get(siblings);
  };

  next = (selector) =>
    Dom.get(
      this.elementList
        .filter(
          (element) =>
            element.nextElementSibling &&
            (!selector || element.nextElementSibling.matches(selector)),
        )
        .map((element) => element.nextElementSibling),
    );

  /**
   * @description Get the immediately preceding sibling of each element in the set of matched elements. If a selector is provided, it retrieves the previous sibling only if it matches that selector. (based on https://api.jquery.com/prev)
   */
  prev = (selector) =>
    Dom.get(
      this.elementList
        .filter(
          (element) =>
            element.previousElementSibling &&
            (!selector || element.previousElementSibling.matches(selector)),
        )
        .map((element) => element.previousElementSibling),
    );

  eq = (index = 0) => {
    if (index < 0) return Dom.get([this.elementList[this.elementList.length + index]]);

    return Dom.get([this.elementList[index]]);
  };

  each = (callback) => {
    if (!callback) return;

    this.elementList?.forEach((element, index) => callback.call(element, index, element));
  };

  add = (selector) => {
    const newDom = Dom.get(selector);

    newDom.elementList = [...this.elementList, ...newDom.elementList];
    newDom.element = newDom.elementList[0];

    return newDom;
  };

  scroll = (...params) => eventManager.call(this, 'scroll', ...params);

  scrollTop = () => {
    if (this.element instanceof Window) return this.element?.scrollY || 0;
    if (this.element instanceof Document) return this.element?.scrollingElement.scrollTop || 0;

    return this.element?.scrollTop || 0;
  };
}

/**
 * @description Module that emulates some of the jQuery base functionalities using HTML5 apis.
 */
export const dom = (...params) => {
  if (typeof params[0] === 'function') return Dom.get(document).ready(params[0]);

  return Dom.get(...params);
};
dom.camelCase = Dom.camelCase;
dom.map = Dom.map;
dom.ajaxSetup = Dom.ajaxSetup;
dom.ajax = Dom.ajax;
dom.trim = Dom.trim;
dom.extend = (...params) => {
  if (params.length === 1 && isObject(params[0])) {
    Object.assign(dom, params[0]);

    return dom;
  }

  return Dom.extend(...params);
};
dom.fn = Dom.prototype;

if (!window.dom) {
  window.dom = dom;
}

if (window.deltatre.jqueryDisabled) {
  window['$'] = dom;
  window['jquery'] = dom;
} else if (window['$'] && !window['jquery']) {
  window['jquery'] = window['$'];
}

export default dom;
