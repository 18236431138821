import { D3Xhr } from './d3xhr';

export const ajax = (url, options) => {
  const {
    async = true,
    username,
    password,
    url: urlOption,
    method,
    type,
    beforeSend,
    xhrFields,
    xhr,
    timeout,
    contentType,
  } = options;

  const request = xhr || new XMLHttpRequest();

  const currentUrl = handleCache(url || urlOption, options);

  request.open(method || type, currentUrl, async, username, password);

  if (contentType) request.setRequestHeader('Content-Type', contentType);

  if (timeout) request.timeout = timeout;

  if (xhrFields) Object.entries(xhrFields).forEach(([key, value]) => (request[key] = value));

  if (beforeSend) beforeSend(request);

  return new D3Xhr(request, options);
};

const handleCache = (url = '', { cache, type = 'GET', method, dataType } = {}) => {
  const currentType = (method || type).toLowerCase();

  if (currentType !== 'get' && currentType !== 'head') return url;

  let shouldCache = cache;

  if (shouldCache === undefined) {
    const lowerDataType = dataType?.toLowerCase();
    shouldCache = lowerDataType !== 'script' && lowerDataType !== 'jsonp';
  }

  if (shouldCache) return url;

  const hasQuery = url.includes('?');
  const cacheQuery = `_=${new Date().getTime()}`;

  return `${url}${hasQuery ? '&' : '?'}${cacheQuery}`;
};
