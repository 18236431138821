/*
 * Handles the Video Mark of THEOplayer
 */
(function (window, d3) {
  'use strict';

  // define config
  let utils = d3 && d3.utils;

  // check dependencies
  if (!utils) {
    throw new Error('D3vp:: videoMark.utils missing');
  }

  // namespace
  d3.ocsvp = d3.ocsvp || {};
  d3.ocsvp.videomark = d3.ocsvp.videomark || {};

  let namespace = d3.ocsvp.videomark,
    videoMarkLocalStorageKeySeed = 'video-mark-',
    videoMarkLocalStorageKey = '';

  //Video Mark
  namespace.setVideoMarkKey = function (player, slug) {
    let videoMarkType = 'standard-';

    if (
      !utils.code.checkIsNil(player.options) &&
      !utils.code.checkIsNil(player.options.isOriginalSerie) &&
      utils.code.setBooleanFromString(player.options.isOriginalSerie)
    ) {
      videoMarkType = 'original-serie-';
    }

    videoMarkLocalStorageKey = videoMarkLocalStorageKeySeed + videoMarkType + slug;
  };

  namespace.setVideoMark = function (eventData, player) {
    if (
      !isNaN(parseFloat(player.playerInstance.currentTime)) &&
      parseFloat(player.playerInstance.currentTime) > 0 &&
      !utils.code.setBooleanFromString(player.playerInstance.ads.playing)
    ) {
      eventData.duration = player.playerInstance.duration;

      if (player.playerInstance.duration - player.playerInstance.currentTime < 2) {
        localStorage.removeItem(videoMarkLocalStorageKey);
      } else {
        //add meta to marker object for ContinueWatching module
        if (
          !!player &&
          !!player.currentContent &&
          !!player.currentContent.metaData &&
          !!player.currentContent.metaData.roofline
        ) {
          eventData.rooflinePrimary =
            eventData.rooflinePrimary || player.currentContent.metaData.roofline.primary;
          eventData.rooflineSecondary =
            eventData.rooflineSecondary || player.currentContent.metaData.roofline.secondary;
          eventData.linkPrimary =
            eventData.linkPrimary || player.currentContent.metaData.roofline.linkPrimary;
          eventData.linkSecondary =
            eventData.linkSecondary || player.currentContent.metaData.roofline.linkSecondary;
          if (!!eventData.linkPrimary && eventData.linkPrimary.indexOf('original-series') > -1) {
            eventData.isOriginalSerie = 'true';
          }
        }
        if (document.querySelector("meta[name='languageUrl']")) {
          eventData.languageUrl =
            eventData.languageUrl ||
            document.querySelector("meta[name='languageUrl']").getAttribute('content');
        }
        if (!!player && !!player.currentContent) {
          eventData.image = eventData.image || player.currentContent.placeholder;
          eventData.title = eventData.title || player.currentContent.title;
          eventData.slug = eventData.slug || player.currentContent.slug;
          eventData.url = window.location.href;
          eventData.entityId = eventData.entityId || player.currentContent.entityId;
          if (player.currentContent.metaData) {
            eventData.series = eventData.series || player.currentContent.metaData.seriesTitle;
          }
        }

        localStorage.setItem(videoMarkLocalStorageKey, JSON.stringify(eventData));
      }
    }
  };

  /*
   * Manages initial video mark(i.e. starting position)
   *
   * */
  namespace.setPosition = function (player) {
    let currentTime = 0;

    if (
      !isNaN(parseFloat(player.playerInstance.currentTime)) &&
      !utils.code.setBooleanFromString(player.playerInstance.ads.playing)
    ) {
      let videoMark = JSON.parse(localStorage.getItem(videoMarkLocalStorageKey));

      if (!utils.code.checkIsNil(videoMark)) {
        if (
          parseFloat(player.playerInstance.currentTime) !==
            parseFloat(player.playerInstance.duration)
        ) {
          currentTime = videoMark.currentTime;
        }
      }

      player.playerInstance.currentTime = currentTime;
    }
  };
  //End Video Mark

  //helpers
})(window, (window.deltatre || {}));
