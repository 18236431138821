export function getParents(selector) {
  return this.elementList.reduce((list, curr) => searchElementAncestors(list, curr, selector), []);
}

/**
 *
 * @param {HTMLElement} element
 * @param {string | undefined} selector
 */
const searchElementAncestors = (list, element, selector) => {
  if (element.tagName === 'html' || !element.parentElement) return list;

  return searchElementAncestors(
    getElementList(list, element, selector),
    element.parentElement,
    selector,
  );
};

const getElementList = (list, element, selector) =>
  (selector && element.parentElement.matches(selector)) || !selector
    ? [...list, element.parentElement]
    : list;
